import React , { useState, useEffect, useRef, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ReactTooltip from 'react-tooltip';
import { useHotkeys } from 'react-hotkeys-hook'
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import User from '../Task/TaskUsers/User.js';
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";

const TaskUsers = ({task, usersStrings, organizationUser, project_id, setTask}) => {
  const {fullUsers, users} = useContext(TaskboardContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  // const [users, setUsers] = useState([]);

  useHotkeys('m', (event) => {
    event.preventDefault();
    displayMenu(event);
  }, [displayMenu]);


  const labelMenuAnchorRef = useRef(null);
  const { show } = useContextMenu({
    id: `${task.token}-task-users`
  });
  function displayMenu(e){
    e.preventDefault();
    e.stopPropagation();
    
    const anchorRect = labelMenuAnchorRef.current.getBoundingClientRect();
    
    show({
      event: e,
      position: {
        x: anchorRect.left,
        y: anchorRect.bottom
      }
    });
  }
  

  useEffect(() => {
    // fetchUsers();
  }, []);

  // const fetchUsers = () => {
  //   axios.get(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/users.json`)
  //   .then(function(response){
  //     console.log(response);
  //     if(response.data.success){
  //       setUsers(response.data.users)
  //     } else {

  //     }
  //   })
  //   .catch(function(error){
  //     console.log(error)
  //     notice("An error occured")
  //   })
  //   .then(function () {
  //     // always executed
  //   });
  // }

  const task_users_list = task.task_users.map(task_user => {
    const removeTaskUser = () => {
      axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users/${task_user.token}`)
      .then(function(response){
        console.log(response);
        if(response.data.success){
          // refetchData();
          setTask(prevTask => ({...prevTask, task_users: prevTask.task_users.filter(tu => tu.token !== task_user.token)}));
        } else {
  
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return(
      <div className="task-user-item hover-parent-opacity animated fadeIn animate btn btn-small-task-question-answer background-hover">
        <i className="fas fa-times task-user-item-delete hover-child-opacity" onClick={removeTaskUser}></i>
        <img src={task_user.user.avatar} className="avatar-small mr-8"/>
        <span className="font-13">{task_user.user.username}</span>
      </div>
    )
  })

  const users_list = (fullUsers || users)?.filter(user => !task.task_users.some(taskUser => taskUser.user.id === user.id)).filter(user => usersStrings.includes(user.username)).map(user => {
    return(
      <User user={user} task={task} project_id={project_id} setTask={setTask} />
    )
  })

  return(
    <React.Fragment>
      <div className="task-users-wrapper">
        
        {task_users_list}

        {(organizationUser && !location.href.includes("/portal/o/")) && 
          <div class="btn-group task-question-answer">
            <a ref={labelMenuAnchorRef} onClick={displayMenu} type="button" class="badge animate border-all background-hover color-1" data-html={true} data-tip="Assign a member <span class='keybind'>M</span>">
              <i class="fas fa-plus dark-color-black"></i>
            </a>
            
            <Menu id={`${task.token}-task-users`}>
              {users_list}
            </Menu>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default TaskUsers