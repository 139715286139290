import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Select from 'react-select';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import DiscountForm from "../Discounts/DiscountForm.js";

const UnsubscribeSettings = ({service, refetchData, closeModal}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [limitationsOpen, setLimitationsOpen] = useState(service.minimum_months ? true : false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [minimumMonths, setMinimumMonths] = useState(service.minimum_months);
  const [stripeAccount, setStripeAccount] = useState(null);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = () => {
    axios.get(`/api/o/${match.params.organization_id}/coupons.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setCoupons(response.data.coupons);
        setStripeAccount(response.data.stripe_account);
        if (service.coupon_id) {
          console.log(service.coupon_id);
          console.log(response.data.coupons);
          const matchingCoupon = response.data.coupons.find(coupon => coupon.id === service.coupon_id);
          if (matchingCoupon) {
            console.log("matchingCoupon");
            console.log(matchingCoupon);
            setSelectedCoupon({ value: matchingCoupon.token, label: matchingCoupon.terms });
          }
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/services/${service.token}/update_retention_coupon`, {
      coupon_id: selectedCoupon.value,
      minimum_months: minimumMonths
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Retention settings updated");
        closeModal();
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleCouponChange = (selectedOption) => {
    if (selectedOption.value === 'add_new') {
      setOpen(true);
    } else {
      setSelectedCoupon(selectedOption);
    }
  };


  return(
    <React.Fragment>
      <div className="row">
        <h4 className="mb-0">Retention settings</h4>
        <p className="opacity-6 mb-20 font-12">
          Increase your retention by offering your customers a discount to renew their subscription.
        </p>
        
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Offer a discount</label>
            <Select
              options={[
                ...coupons.map(coupon => ({ value: coupon.token, label: coupon.terms })),
                { value: 'add_new', label: <span><i className="fal fa-plus mr-5"></i>Add retention coupon</span> }
              ]}
              onChange={handleCouponChange}
              value={selectedCoupon}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />

            <a style={{marginLeft: "-12px"}} onClick={() => setLimitationsOpen(!limitationsOpen)} className="color-1 font-12 opacity-6 mt-15 btn btn-small background-hover"><i className="fal fa-funnel-dollar mr-8"></i>Set limit</a>
          </div>

          {limitationsOpen && 
            <div className="field animated fadeInUp">
              <label>Minimum months</label>
              <input type="number" className="form-control" defaultValue={minimumMonths} onChange={(e) => setMinimumMonths(e.target.value)}/>
              <p className="font-12 opacity-6 mt-5">Set the minimum number of months that the customer must be subscribed before you offer them the retention coupon.</p>
            </div>
          }

          <div className="field text-right">
            <button disabled={disabled} type="submit" className="btn btn-primary"><i className="fal fa-save mr-10"></i>Save</button>
          </div>
        </form>
      </div>
      
      {stripeAccount && 
        <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-600'}} center>
          <DiscountForm closeModal={() => setOpen(false)} refetchData={fetchCoupons} currency={stripeAccount.stripe_account_data.default_currency}/>
        </Modal>
      }
    </React.Fragment>
  )
}

export default UnsubscribeSettings;