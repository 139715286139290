import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import Filestack from "./Filestack.js";
import { Modal } from 'react-responsive-modal';
import OnboardingStripeProducts from "../../Onboardings/Sellers/OnboardingStripeProducts.js";
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Currency from "../Discounts/Currency.js";
import getSymbolFromCurrency from 'currency-symbol-map'
import ExistingServiceAddonForm from "./ExistingServiceAddonForm.js";
import TipTap from "../../Shared/TipTap.js";
import ImportStripePrices from "./ImportStripePrices.js";

const Form = ({service, edit, refetchData, closeModal, currency, addon}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [billingType, setBillingType] = useState('');
  // const [prices, setPrices] = useState([]);
  const [prices, setPrices] = useState([
    {
      unit_amount: '',
      recurring: { 
        interval: '',
        intervalCount: 1, // Default to 1
      },
      trial: '',
      customAmount: false, // Default to false
    }
  ]);
  
  const [additionalOptions, setAdditionalOptions] = useState(false);
  const [image, setImage] = useState(edit ? service.preview_image : null);
  const [title, setTitle] = useState(edit ? service.title : null);
  const [description, setDescription] = useState(edit ? service.description : null);
  const [oneTimePrices, setOneTimePrices] = useState([]);
  const [disablePause, setDisablePause] = useState(edit ? service.disable_pause : false)
  const [disableQuantity, setDisableQuantity] = useState(edit ? service.disable_quantity : false);
  const [minimumQuantity, setMinimumQuantity] = useState(edit ? service.minimum_quantity : null);
  const [maxQuantity, setMaxQuantity] = useState(edit ? service.max_quantity : null);
  const [requireMinimumDays, setRequireMinimumDays] = useState(edit ? (service.minimum_days != null ? true : false) : false)
  const [minimumDays, setMinimumDays] = useState(edit ? (service.minimum_days / 30) : null)
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(edit ? service.selected_services : []);
  const [requireAddon, setRequireAddon] = useState(edit ? service.require_addon : false);
  // const [serviceAddons, setServiceAddons] = useState([]);
  // const [selectedServiceAddons, setSelectedServiceAddons] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(edit ? service.currency : currency)
  const [open, setOpen] = useState(false);
  const [openImportStripePrices, setOpenImportStripePrices] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [newService, setNewService] = useState(false);
  const [creditsForm, setCreditsForm] = useState(edit ? service.credit_type : false);
  const [credits, setCredits] = useState(edit ? service.credits : null);
  const [creditsName, setCreditsName] = useState(edit ? service.credits_name : null);
  const [addonOnly, setAddonOnly] = useState(edit ? service.addon : false);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(edit ? service.cancel_at_period_end : null )
  const [advanced, setAdvanced] = useState(false);

  useEffect(() => {
    if(edit){
      // RECURRING
      const serviceRecurringPrices = service.service_prices.filter(price => price.recurring).map(price => {
        return {
          id: price.price_identifier,
          unit_amount: price.amount / 100,
          recurring: {
            interval: price.recurring_interval
          },
          trial: price.stripe_data?.metadata.trial_period_days || "", 
          archive: price.archive
        };
      });
      
      console.log(serviceRecurringPrices);
      if(serviceRecurringPrices.length > 0){
        setPrices(serviceRecurringPrices);
        setBillingType('recurring');
      }

      // ONE_TIME
      const serviceOneTimePrices = service.service_prices.filter(price => !price.recurring).map(price => {
        return {
          id: price.price_identifier,
          unit_amount: price.amount / 100,
          quantity: price.stripe_data?.transform_quantity, 
          archive: price.archive
        };
      });
      
      console.log(serviceOneTimePrices);
      if(serviceOneTimePrices.length > 0){
        setOneTimePrices(serviceOneTimePrices);
        setBillingType('one-time')
      }
    }

    if(addon){
      fetchServices();
    } else {
      // fetchServiceAddons();
    }
  },[]);

  const fetchServices = () => {
    axios.get(`/api/o/${match.params.organization_id}/services.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServices(response.data.services); 
        setLoaded(true)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
    });
  }

  const serviceOptions = services.filter((item) => !selectedServices.some((selected) => selected.value === item.token)).map((item) => ({
    value: item.token,
    label: item.title,
  }));
  
  // RECURRING PRICES
  const handlePriceChange = (index, unitAmount) => {
    const updatedPrices = [...prices];
    updatedPrices[index].unit_amount = unitAmount;
    setPrices(updatedPrices);
  };

  const handleTrialChange = (index, trialValue) => {
    const updatedPrices = [...prices];
    updatedPrices[index].trial = trialValue;
    setPrices(updatedPrices);
  };
  

  const handleAddPrice = () => {
    setPrices([...prices, { unit_amount: '', recurring: { interval: '' }, trial: '' }]);
  };

  const handleRemovePrice = (index) => {
    const updatedPrices = [...prices];
    updatedPrices.splice(index, 1);
    setPrices(updatedPrices);
  };

  // ONE-TIME PRICES
  const handleAddOneTimePrice = () => {
    setOneTimePrices([...oneTimePrices, { unit_amount: '', quantity: 1 }]);
  };

  const handleOneTimePriceChange = (index, field, value) => {
    const updatedOneTimePrices = [...oneTimePrices];
    updatedOneTimePrices[index][field] = value;
    setOneTimePrices(updatedOneTimePrices);
  };

  const handleRemoveOneTimePrice = (index) => {
    const updatedOneTimePrices = [...oneTimePrices];
    updatedOneTimePrices.splice(index, 1);
    setOneTimePrices(updatedOneTimePrices);
  };

  const handleServiceSelection = (selectedOptions, action) => {
    setSelectedServices(selectedOptions);

    if(edit){
      if (action.action === 'remove-value') {
        // Item was removed
        console.log('Item removed:', selectedOptions);
        // Execute your function here for item removal
      } else if (action.action === 'select-option') {
        // Item was added
        console.log('Item added:', selectedOptions);
        // Execute your function here for item addition
      }
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/services`, {
      title: title, 
      description: description, 
      active: true,
      preview_image: image, 
      prices: prices, 
      one_time_prices: oneTimePrices, 
      disable_pause: disablePause,
      disable_quantity: disableQuantity,
      minimum_quantity: minimumQuantity, 
      max_quantity: maxQuantity,
      minimum_days: (requireMinimumDays === true ? (minimumDays * 30) : null),
      addon: addonOnly,
      selected_services: (addon ? selectedServices : null),
      require_addon: requireAddon, 
      currency: selectedCurrency, 
      credits_name: creditsName,
      credits: credits,
      credit_type: creditsForm, 
      cancel_at_period_end: cancelAtPeriodEnd
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/services/${service.token}`, {
      title: title, 
      description: description, 
      active: true,
      preview_image: image, 
      prices: prices, 
      one_time_prices: oneTimePrices, 
      disable_pause: disablePause, 
      disable_quantity: disableQuantity,
      minimum_quantity: minimumQuantity, 
      max_quantity: maxQuantity,
      minimum_days: (requireMinimumDays === true ? (minimumDays * 30) : null),
      require_addon: requireAddon, 
      currency: selectedCurrency,
      credits_name: creditsName,
      credits: credits,
      credit_type: creditsForm,
      addon: addonOnly, 
      cancel_at_period_end: cancelAtPeriodEnd
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const deleteServicePrice = (service_price) => {
    axios.post(`/api/o/${match.params.organization_id}/services/${service.token}/service_prices/${service_price.id}/archive`, {
      archive: true
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Archived service price")
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const clearOption = { label: '+ New service', value: null }; // Option to clear the selection
  const handleServiceSelect = (selectedOption) => {
    if(selectedOption.value == null){
      setSelectedService(null)
      setNewService(true)
    } else {
      setSelectedService(selectedOption);
      setNewService(false)
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };

  return(
    <React.Fragment>   
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>    
        {(addon) && 
          <div className="field">
            <label>Enable for</label>
            <Select
              options={serviceOptions}
              isMulti
              value={selectedServices}
              onChange={handleServiceSelection}
              isDisabled={edit}
              placeholder="Select services..."
              styles={customStyles} 
            />
            <p className="dark-color-black opacity-4 font-12 mt-10">Addon will appear on the checkout form for these services.</p>
          </div>
        }

        {(addon && selectedServices != null && selectedServices.length > 0) && 
          <div className="field">
            <label>Choose existing prices or add new one?</label>
            <Select
              options={[...serviceOptions, clearOption]}
              value={selectedService}
              onChange={handleServiceSelect}
              className="min-width-350"
              placeholder="Select..."
              styles={customStyles} 
            />
            <p className="dark-color-black opacity-4 font-12 mt-10">You can offer an existing service as an add-on or create a new one.</p>
          </div>
        }
        
        {(!addon || newService) ?(
          <React.Fragment>
            <form className="row no-margin" onSubmit={edit ? handleUpdate : handleSubmit}>     
              <div className="field daas-service-image-preview-wrapper">
                {image == null ? (
                  <img src="https://placehold.co/400x400/EEE/31343C" className="daas-service-image-preview" style={{width: "60px", height: "60px"}}/>
                ):(
                  <div>
                    <img src={image} className="daas-service-image-preview" style={{width: "60px", height: "60px"}}/>
                    <i class="far fa-times daas-service-image-remove background-hover" onClick={() => setImage(null)}></i>
                  </div>
                )}
                <Filestack setImage={setImage}/>
              </div>

              <div className="field row">
                <div className="col-md-6 col-12 pr-15">
                  <label>Name</label>
                  <input defaultValue={title} onChange={(e) => setTitle(e.target.value)} className="form-control" placeholder="Short title of the service" required={true}/>
                </div>

                <div className="col-md-6 col-12">
                  <Currency setSelectedCurrency={setSelectedCurrency} selectedCurrency={selectedCurrency} edit={edit}/>
                </div>
              </div>

              <div className="field">
                <label>Description</label>
                <TipTap comment={description} setComment={setDescription} users={[]}/>
              </div>
              
              {/* RECURRING */}
              <div className="row field no-margin animated fadeIn">
                <div className="row field no-margin">
                  {/* New */}
                  <div className="row animated fadeInUp">
                    {prices.map((price, index) => (
                      <div key={index} className="row">
                        <div className="col-md-5 pr-15 col-12 field">
                          <label>Price</label>
                          <div className="input-group">
                            <span className="input-group-addon">{getSymbolFromCurrency(selectedCurrency)}</span>
                            <input
                              disabled={price.id != null}
                              className="form-control"
                              type="number"
                              placeholder="Unit Amount"
                              value={price.unit_amount}
                              onChange={(e) => handlePriceChange(index, e.target.value)}
                              required={true}
                            />
                            <span className="input-group-addon">{selectedCurrency || currency}</span>
                          </div>
                        </div>

                        <div className="col-md-5 pr-15 col-12 field">
                          <label>Frequency</label>


                          {price.customAmount ? (
                            <div>
                              <div class="input-group">
                                <span class="input-group-addon">Every</span>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Custom Amount"
                                  defaultValue={price.recurring.intervalCount} // Use intervalCount for the value
                                  onChange={(e) => {
                                    const customIntervalCount = parseInt(e.target.value);
                                    if (!isNaN(customIntervalCount)) {
                                      const updatedPrices = [...prices];
                                      updatedPrices[index].recurring.intervalCount = customIntervalCount;
                                      setPrices(updatedPrices);
                                    }
                                  }}
                                  required={true}
                                />
                                <span class="input-group-btn" style={{width: "100px"}}>
                                  <select 
                                    required
                                    value={price.recurring.interval} // Use interval for the selected value
                                    onChange={(e) => { 
                                      const selectedInterval = e.target.value;
                                      setPrices(prevPrices => {
                                        const updatedPrices = [...prevPrices];
                                        updatedPrices[index].recurring.interval = selectedInterval;
                                        return updatedPrices;
                                      });
                                    }}
                                  >
                                    <option value="">Interval</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          ):(
                            <select
                              disabled={price.id != null}
                              value={price.recurring.interval}
                              onChange={(e) => {
                                const selectedInterval = e.target.value;
                                setPrices(prevPrices => {
                                  const updatedPrices = [...prevPrices];
                                  updatedPrices[index].recurring.interval = selectedInterval;
                                  // If "Custom" is selected, set customAmount to true
                                  if (selectedInterval === 'custom') {
                                    updatedPrices[index].customAmount = true;
                                  } else {
                                    // For other options, set customAmount to false
                                    updatedPrices[index].customAmount = false;
                                  }
                                  return updatedPrices;
                                });
                              }}
                              required
                            >
                              <option value="">Select Interval</option>
                              <option value="day">Daily</option>
                              <option value="week">Weekly</option>
                              <option value="month">Monthly</option>
                              <option value="year">Yearly</option>
                              <option value="custom">Custom</option>
                            </select>
                          )}
                        </div>

                        {!addon && 
                          <div className="col-md-2">
                            <label>Trial days</label>
                            <input
                              disabled={price.id != null}
                              className="form-control"
                              type="number"
                              placeholder="Trial Period"
                              defaultValue={price.trial}
                              onChange={(e) => handleTrialChange(index, e.target.value)}
                            />
                          </div>
                        }

                        <div className="col-12 text-right service-form-price-delete">

                          {(price.id != null) ? (
                            <React.Fragment>
                              {price.archive ? (
                                "Archived"
                              ):(
                                <a className="color-red" onClick={() => {price.id != null && deleteServicePrice(price); }}>
                                  Archive
                                </a>
                              )}
                            </React.Fragment>
                          ):(
                            <a className="color-red" onClick={() => handleRemovePrice(index)}>Remove</a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* ONE_TIME */}
              <div className="row field no-margin animated fadeIn">
                {oneTimePrices.map((item, index) => (
                  <div key={index} className="row animated fadeInUp">
                    <div className="col-md-12 pr-15 col-12 field">
                      <label>Amount</label>
                      <div className="input-group">
                        <span className="input-group-addon">{getSymbolFromCurrency(selectedCurrency)}</span>
                        <input
                          disabled={item.id != null}
                          type="number"
                          className="form-control"
                          placeholder="Amount"
                          value={item.unit_amount}
                          onChange={(e) => handleOneTimePriceChange(index, 'unit_amount', e.target.value)}
                          required
                        />
                        <span className="input-group-addon">{selectedCurrency || currency}</span>
                      </div>
                    </div>

                    <div className="col-12 text-right service-form-price-delete">
                      {(item.id != null) ? (
                        <React.Fragment>
                          {item.archive ? (
                            "Archived"
                          ):(
                            <a className="color-red" onClick={() => {item.id != null && deleteServicePrice(item);}}>
                              Archive
                            </a>
                          )}
                        </React.Fragment>
                      ):(
                        <a className="color-red" onClick={() => handleRemoveOneTimePrice(index)}>Remove</a>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* BILLING TYPE */}
              {(!addon || (prices.length == 0 && oneTimePrices.length == 0)) &&
                <React.Fragment>
                  <div className="row no-margin">
                    <label>Pricing strategy</label>
                    <div className="field">
                      <div className="col-12" style={{display: "flex"}}>
                        {selectedService === null && 
                          <React.Fragment>
                              <React.Fragment>
                                <a onClick={() => {handleAddPrice();}} className={`btn btn-small btn-primary-outline mr-5 color-1 dark-color-black color-blue-hover`}><i class="far fa-calendar-alt mr-10"></i>Add subscription</a>
                                <a onClick={() => {handleAddOneTimePrice();}} className={`btn btn-small btn-primary-outline mr-5 color-1 dark-color-black color-blue-hover`}><i class="far fa-credit-card-front mr-10"></i>Add single-payment</a>
                              </React.Fragment>
                          </React.Fragment>
                        }
                        </div>
                      </div>
                    </div>
                </React.Fragment>
              }

            <div className="field">
              <a className={`color-1 btn btn-small background-hover opacity-6 opacity-10-hover ${advanced ? "background-active" : ""}`} onClick={() => {setAdvanced(!advanced)}}>Advanced options</a>
            </div>

            {advanced && 
              <div className="animated fadeInUp">
                {/* DISABLE PAUSING */}
                <div className="field mt-25">
                  <div className="service-form-disable-pause-wrapper">
                    <Toggle icons={false} checked={disablePause} onChange={() => {setDisablePause(!disablePause)}}/>
                    <div className="ml-5 color-black-always font-13">Disable pausing</div>
                    <i className="far fa-question-circle ml-5" data-tip="This will prevent your client from pausing their subscription."></i>
                  </div>
                </div>
                
                {!addon && 
                  <div className="field">
                    <div className="service-form-disable-pause-wrapper">
                      <Toggle icons={false} checked={requireAddon} onChange={() => {setRequireAddon(!requireAddon)}}/>
                      <div className="ml-5  color-black-always font-13">Require an add-on</div>
                      <i className="far fa-question-circle ml-5" data-tip="Customer will be required to add at least 1 add-on during checkout."></i>
                    </div>
                  </div>
                }

                <div className="field">
                  <div className="service-form-disable-pause-wrapper">
                    <Toggle icons={false} checked={addonOnly} onChange={() => {setAddonOnly(!addonOnly)}}/>
                    <div className="ml-5  color-black-always font-13">Add-on only?</div>
                    <i className="far fa-question-circle ml-5" data-tip="This will hide it from the catalog"></i>
                  </div>
                </div>

                <div className="field">
                  <div className="service-form-disable-pause-wrapper">
                    <Toggle icons={false} checked={disableQuantity} onChange={() => {setDisableQuantity(!disableQuantity)}}/>
                    <div className="ml-5  color-black-always font-13">Disable changing quantity</div>
                  </div>
                </div>

                <div className="field">
                  <div className="service-form-disable-pause-wrapper">
                    <Toggle icons={false} checked={cancelAtPeriodEnd} onChange={() => {setCancelAtPeriodEnd(!cancelAtPeriodEnd)}}/>
                    <div className="ml-5  color-black-always font-13">Cancel at end of period</div>
                    <i className="far fa-question-circle ml-5" data-tip="This will cancel cancel the subscription at the end of the first billing period."></i>
                  </div>
                </div>
                
                {/* Track credits */}
                <div className="field">
                  <div className="service-form-disable-pause-wrapper field">
                    <Toggle icons={false} checked={creditsForm} onChange={() => {setCreditsForm(!creditsForm); setCredits(null); setCreditsName(null)}}/>
                    <div className="ml-5 color-black-always font-13">Track credits</div>
                    <i className="far fa-question-circle ml-5" data-tip="Add the number of credits this service offers and track them easily."></i>
                  </div>

                  {/* CREDITS */}
                  {creditsForm && 
                    <div className="row animated fadeInUp">
                      <div className="col-6 pr-15">
                        <label>Label</label>
                        <input className="form-control-small form-control" placeholder="ie Credits, Hours, or Tasks" value={creditsName} onChange={(e) => setCreditsName(e.target.value)}/>
                      </div>

                      <div className="col-6">
                        <label>Quantity</label>
                        <input className="form-control-small form-control" placeholder="Quantity of credits to track" min="1" type="number" onChange={(e) => setCredits(e.target.value)} value={credits}/>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
              
              {/* ALLOW QUANTITY */}
              {addon && 
                <React.Fragment>
                  <div className="field">
                    <div className="service-form-disable-pause-wrapper">
                      <Toggle icons={false} checked={disableQuantity} onChange={() => {setDisableQuantity(!disableQuantity)}}/>
                      <div className="ml-5  color-black-always font-13">Let customers adjust quantity</div>
                    </div>
                  </div>
                  {disableQuantity && 
                  <div className="field" style={{paddingLeft: "40px"}}>
                    <div className="service-form-quantity-inputs">
                      <span>Between</span> <input min="1" defaultValue={1} className="form-control-small form-control ml-5 mr-5" type="number" style={{width: "55px"}} value={minimumQuantity} onChange={(e) => setMinimumQuantity(e.target.value)}/> and <input min="1" defaultValue={"99"} className="form-control-small form-control ml-5 mr-5" type="number" style={{width: "55px"}} value={maxQuantity} onChange={(e) => setMaxQuantity(e.target.value)}/>
                    </div>
                  </div>
                  }

                  {/* MINIMUM DAYS */}
                  <div className="field">
                    <div className="service-form-disable-pause-wrapper">
                      <Toggle icons={false} checked={requireMinimumDays} onChange={() => {setRequireMinimumDays(!requireMinimumDays)}}/>
                      <div className="ml-5 color-black-always">Require minimum months commitment</div>
                      <i className="far fa-question-circle ml-5" data-tip="Disable customer from canceling subscription until set months have passed."></i>
                    </div>
                  </div>
                  {requireMinimumDays && 
                    <div className="field" style={{width: "220px", paddingLeft: "55px"}}>
                      <div class="input-group">
                        <input type="number" class="form-control-small form-control" aria-describedby="basic-addon2" min="1" defaultValue={1} value={minimumDays} onChange={(e) => setMinimumDays(e.target.value)}/>
                        <span class="input-group-addon" id="basic-addon2" style={{padding: "8px 20px"}}>months</span>
                      </div>
                    </div>
                  }
                </React.Fragment>
              }

              <div className="field text-right mt-15">
                <button disabled={disabled || (prices.length == 0 && oneTimePrices.length == 0)} className="btn btn-primary" type="submit">Submit</button>
              </div>
            </form>
          </React.Fragment>
        ):(
          <React.Fragment>
            {selectedService != null && 
              <ExistingServiceAddonForm selectedServices={selectedServices} service_id={selectedService.value} refetchData={refetchData} closeModal={closeModal}/>
            }
          </React.Fragment>
        )}

      {(!addon && !edit) && 
        <div className="field text-center">
          <div className="line-or-wrapper">
            <div class="line"></div>
            <div class="or">or</div>
            <div class="line"></div>
          </div>
          
          {edit ? (
            <>
              {/* <a onClick={() => {setOpenImportStripePrices(true);} } className="color-1 dark-color-black display-block mt-25"><i className="fab fa-stripe-s mr-5"></i>Import pricing from Stripe</a> */}
            </>
          ):(
            <a onClick={() => {setOpen(true);} } className="color-1 dark-color-black display-block mt-25"><i className="fab fa-stripe-s mr-5"></i>Import from Stripe</a>
          )}

          <Modal open={openImportStripePrices} onClose={() => setOpenImportStripePrices(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
            <ImportStripePrices service={service} setImportFinished={() => {setOpenImportStripePrices(false); refetchData(); closeModal(true)}}/>
          </Modal>
          
          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
            <OnboardingStripeProducts setImportFinished={() => {setOpen(false); refetchData(); closeModal(true)}}/>
          </Modal>
        </div>
      }
    </React.Fragment>
  )
}

export default Form