import React , { useState, useEffect, useRef } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Files from "./Files.js";
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";
import FilestackAttachements from "../TaskForms/FilestackAttachements.js";
import { PickerOverlay, PickerInline } from 'filestack-react';

const Field = ({project_id, field, task_id, refetchData, task, setTask}) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(field.value);
  const editFieldRef = useRef(null);
  const [openFilepicker, setOpenFilepicker] = useState(false);
  
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleUpdate();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      handleCancel();
    }
  };

  const handleUpdate = () => {
    if (editValue !== field.value) {
      // Update local state immediately
      const updatedTask = {...task};
      const updatedField = updatedTask.field_data.find(f => f.id === field.id);
      if (updatedField) {
        updatedField.value = editValue;
        setTask(updatedTask);
      }

      // Then update on the server
      updateFieldData(editValue);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(field.value);
    setIsEditing(false);
  };
  
  // Function to update field data
  const updateFieldData = (newValue) => {
    axios
      .post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_field_data`, {
        field_token: field.id,
        value: newValue,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          refetchData();
          // notice("Updated successfully")
          // Do something after successfully updating field data
        } else {
          // Handle error if update fails
        }
      })
      .catch(function (error) {
        console.log(error);
        // Handle error
      });
  };

  const renderEditableField = () => {
    const commonProps = {
      value: editValue,
      onChange: (e) => setEditValue(e.target.value),
      onKeyDown: handleKeyDown,
      className: "form-control-small"
    };

    switch (field.type) {
      case 'text':
        if (field.kind === 'date') {
          return (
            <DatePicker
              selected={editValue ? new Date(editValue) : null}
              onChange={(date) => setEditValue(date ? date.toISOString().split('T')[0] : '')}
              className="task-question-answer-input"
              placeholderText="Set Due Date"
              inline
            />
          );
        }
        return <input type="text" {...commonProps} />;
      case 'link':
        return <input type="text" {...commonProps} />;
      case 'textarea':
        return <textarea {...commonProps} />;
      case 'checkbox':
        return (
          <div class="display-flex flex-direction-column align-items-flex-start">
            {field.options.map((option) => (
              <label key={option} className="btn btn-small background-hover display-flex margin-none align-items-center cursor-pointer font-14">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  value={option}
                  checked={editValue.includes(option)}
                  onChange={(e) => {
                    const newValue = e.target.checked
                      ? [...editValue, option]
                      : editValue.filter((v) => v !== option);
                    setEditValue(newValue);
                  }}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'radio':
        return (
          <div>
            {field.options.map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  value={option}
                  checked={editValue === option}
                  onChange={(e) => setEditValue(e.target.value)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return <span>Editing not supported for this field type</span>;
    }
  };

  const uploadAttachements = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
  
    // Update the task's field value
    const updatedTask = {...task};
    const updatedField = updatedTask.field_data.find(f => f.id === field.id);
    if (updatedField) {
      updatedField.value = Array.isArray(updatedField.value) ? [...updatedField.value, ...filesUploaded] : filesUploaded;
      setTask(updatedTask);
    }
  
    // Update the server
    updateFieldData(updatedField.value);
  };
  

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="row full-card-info-wrapper">
        <div className="col-3 pr-15 task-question-answer-label">
          <label className="task-list-label" data-tip={field.label}>

            {(field.type == "text" && field.kind != "date" && field.kind != "link") && 
              <i class="fas fa-horizontal-rule mr-5"></i>
            }

            {field.type == "textarea" && 
              <i class="fas fa-grip-lines mr-5"></i>
            }

            {(field.kind == "link") && 
              <i class="fas fa-link mr-5"></i>
            }

            {(field.kind == "date") && 
              <i class="fas fa-calendar mr-5"></i>
            }

            {field.type == "upload" && 
              <i class="fas fa-upload mr-5"></i>
            }

            {field.type == "checkbox" && 
              <i class="fas fa-check-square mr-5"></i>
            }

            {field.type == "radio" && 
              <i class="fas fa-dot-circle mr-5"></i>
            } 
              {field.label}
          </label>
        </div>

        <div className="col-9 task-question-answer">
          {isEditing ? (
            <div ref={editFieldRef}>
              {renderEditableField()}
              <div className="mt-2">
                <button className="btn btn-primary btn-small mr-8" onClick={handleUpdate}>
                  Update
                </button>
                
                <button className="btn btn-secondary background-hover btn-small" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="cursor-pointer" onClick={field.type != "upload" ? handleEdit : null}>
              {field.type === "upload" && 
                <div>
                  {typeof field.value === 'string' ? (
                    // If field.value is a string, render each link separately
                    field.value.split(', ').map((link, index) => (
                      <p key={index}>
                        <a href={link} target="_blank">{link}</a>
                      </p>
                    ))
                  ) : (
                    <>
                      <a className="btn btn-small background-3 color-1 background-hover" onClick={() => setOpen(true)}>
                        {field.value.length} Uploads
                      </a>
                      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                        <Files attachements={field.value || []} zipFileName={field.label}/>
                      </Modal>
                    </>
                  )}
                  
                  <a className="btn btn-small background-hover color-1" onClick={() => setOpenFilepicker(true)}><i class="far fa-upload"></i></a>
                  {openFilepicker && 
                    <PickerOverlay
                      apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                      onSuccess={(res) => console.log(res)}
                      onUploadDone={(res) => {
                        uploadAttachements(res);
                        setOpenFilepicker(false);
                      }}
                      clientOptinos={{
                        sessionCache:true
                      }}
                      pickerOptions={{
                        onClose: () => {
                          setOpenFilepicker(false);
                        }, 
                        fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                        maxFiles: 15
                      }}
                    />
                  }
                </div>
              }

              {(field.value == "" && field.kind != "date" && field.type != "upload") && <span className="opacity-4">Empty</span>}
    
    
              {field.kind == "link" && 
                <p>
                  <a href={field.value} target="_blank">{field.value}</a>
                </p>
              }
    
              {(field.kind == "text" && field.type == "text") &&
                <p>{field.value}</p>
              }
    
              {field.kind == "textarea" && 
                <p>{field.value}</p>
              }
    
              {field.kind == "date" && 
                <p>{field.value}</p>
              }
    
              {field.type == "textarea" && 
                <p>{field.value}</p>
              }
    
              {field.type == "checkbox" && 
                <p>
                  {field.value.map(item => {
                    return(
                      <span className="btn btn-small-task-question-answer mr-8 background-3 mb-5">{item}</span>
                    )
                  })}
                </p>
              }
    
              {field.type == "radio" && 
                <p>
                  <span className="badge badge-primary mr-10">{field.value}</span>
                </p>
              }
            </div>
          )}
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default Field;