import React , { useState, Fragment, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Dropdown from 'react-bootstrap/Dropdown';

import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import _ from 'lodash';
import TaskLabels from "../../../../Labels/TaskLabels.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";
import TaskLabelPicked from "../../../../Labels/TaskLabelPicked.js";
const TaskDropdown = ({task, refetchData, project_id, organizationUser, displayMenu, setOpenNewLabel, setOpenTaskTemplate, column, subtask, setOpenCover, setArray}) => {
  const {labels, setLabels, setColumns} = useContext(TaskboardContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();
  const { show: showContextMenu } = useContextMenu();

  const archiveTask = () => {
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/archive`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // refetchData();
        // remove task from column
        if(!subtask){
          setColumns(prevColumns => {
            return prevColumns.map(col => {
              if (col.token === task.column.token) {
                return {
                  ...col,
                  cards: col.cards ? col.cards.filter(card => card.token !== task.token) : col.cards,
                  tasks: col.tasks ? col.tasks.filter(t => t.token !== task.token) : col.tasks
                };
              }
              return col;
            });
          });
        } else {
          // refetchData();
          if(setArray){
            setArray(prevArray => {
              return prevArray.filter(item => item.token !== task.token)
            })
          }
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  const deleteTask = () => {
    setDisabled(true);

    axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const moveOut = () => {
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      position: null, 
      parent_task_id: null, 
      // column_id: task.parent_task.column_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // notice("Moved out");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const picked_task_labels_list = task?.task_labels?.map(task_label => {
    return(
      <TaskLabelPicked task_label={task_label} task={task} refetchData={() => {refetchData();}}/>
    )
  })

  const labels_list = labels.filter(label => !task?.task_labels?.some(task_label => task_label.label.id === label.id)).map(label => {
    return(
      <TaskLabels label={label} task={task} refetchData={refetchData} />
    )
  })

  

  return(
    <React.Fragment>
      <a onClick={displayMenu} className="task-dropdown-btn animate grow btn btn-small color-1 background-hover hover-child-opacity"><i class="fas fa-ellipsis-v"></i></a>

      <Menu id={task.token}>
        <Submenu closeOnClick={false} label={<Fragment><i class="fal fa-tag"></i><span>Labels</span></Fragment>}>
          {labels.length > 0 &&
            <>
              {picked_task_labels_list}
              <Separator />
              {labels_list}
            </>
          }
          <Item onClick={() => setOpenNewLabel(true)}>
            <i className="far fa-plus mr-8"></i>New Label
          </Item>
        </Submenu>

        {organizationUser &&
          <Item onClick={() => setOpenTaskTemplate(true)}>
            <i class="fal fa-object-group"></i> {t("Turn into template")}
          </Item>
        }

        {task.parent_task != null &&
          <Item onClick={moveOut}>
            <i class="fal fa-share"></i>{t("Move out")}
          </Item>
        }

        <Item onClick={() => setOpenCover(true)}>
          <i class="fal fa-image-polaroid"></i>{t("Change cover")}
        </Item>
        
        <Item onClick={archiveTask}>
          <i class="fal fa-archive"></i>{t("Archive")}
        </Item>
      </Menu>
    </React.Fragment>
  )
}

export default TaskDropdown