import React , { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { Route, Switch, NavLink,  useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../Shared/UserContext.js";
import { Modal } from 'react-responsive-modal';
import ChatroomSkeleton from './ChatroomSkeleton.js';
const LazyProjectWrapper = lazy(() => import("./ProjectWrapper.js"));
import NewProject from "./New.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import Premium from "../Subscriptions/Premium.js";
import Project from "./Project.js";
import Archives from "./ArchivedProjects/ArchivedProjects.js";
import ReactTooltip from 'react-tooltip';
import Organizations from "../Navigation/Organizations.js";
import Notifications from "../Notifications/Index.js";
import TaskFormsIndex from "../Daas/TaskForms/Index.js";
import Dashboard from "../Daas/Seller/Dashboard.js";
import Settings from "../Daas/Stripe/Settings.js";
import Home from "./Home.js";
import ClientOnboardingsNew from "../ClientOnboarding/New.js";
import TaskBoards from "../Daas/TaskBoards/TaskBoards.js";
import { isMobile } from "react-device-detect";
import ReferralRouter from "../Referrals/ReferralRouter.js";
import ChatroomsWrapper from "../Chatrooms/ChatroomsWrapper.js";
import Reviews from "../Reviews/Reviews.js";
import ServicesWrapper from "../Daas/Services/ServicesWrapper.js";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TrialEnding from "../Organizations/TrialEnding.js";
// import People from "../Users/People.js";
import Themes from "../Themes/Themes.js";
import PluginsIndex from "../Plugins/Index.js";
import Checklist from "../Organizations/Checklist.js";
import lottieConfetti from "../Shared/Lotties/lottie-confetti.json";
import { Player } from "@lottiefiles/react-lottie-player";
// ES6 module syntax
import LocalizedStrings from 'react-localization';
import ActiveTimer from "../TimeTracker/ActiveTimer.js";
import {ProjectContext} from "./ProjectContext.js";
import localForage from "localforage";
import { useTranslation } from 'react-i18next';
import SideNavigationOrganizations from "../Layouts/SideNavigationOrganizations.js";
import KanbanBoardIcon from "../Shared/svg/KanbanBoardIcon.js";
import { useHotkeys } from 'react-hotkeys-hook'

let strings = new LocalizedStrings({
 en:{
   dashboard:"Dashboard",
   boiledEgg:"Boiled egg",
   softBoiledEgg:"Soft-boiled egg",
   choice:"How to choose the egg",
   greeting: "Hello"
 },
 it: {
   dashboard:"salpicadero",
   boiledEgg:"Uovo sodo",
   softBoiledEgg:"Uovo alla coque",
   choice:"Come scegliere l'uovo",
   greeting: "hola"
 }
});

const Projects = ({refetchSubscriptions}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  useHotkeys(`shift+r`, () => history.push(`/o/${match.params.organization_id}/dashboard`), [])
  useHotkeys(`shift+m`, () => history.push(`/o/${match.params.organization_id}/dashboard/my-tasks`), [])
  useHotkeys(`shift+a`, () => history.push(`/o/${match.params.organization_id}/dashboard/analytics`), [])
  useHotkeys(`shift+i`, () => history.push(`/o/${match.params.organization_id}/chatrooms`), [])
  useHotkeys(`shift+p`, () => history.push(`/o/${match.params.organization_id}/dashboard/people`), [])
  const currentUser = useContext(UserContext);
  const organizationAuthorizations = useContext(OrganizationContext)
  const [loaded, setLoaded] = useState(false)
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const onOpenModal = () => { setOpen(true); };
  const [openArchived, setOpenArchived] = useState(false)
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false); 
  const [showSidenav, setShowSidenav] = useState(!isMobile)
  const [projects, setProjects] = useState([]);
  const [privateProjects, setPrivateProjects] = useState([]);
  const [screenshotProject, setScreenshotProject] = useState();
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [testers, setTesters] = useState([]);
  const [openPlugins, setOpenPlugins] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [electron, setElectron] = useState(window && window.process && window.process.type === 'renderer');
  const [hideProjectMenu, setHideProjectMenu] = useState(JSON.parse(localStorage.getItem("hideProjectMenu")))
  const [hideProjects, setHideProjects] = useState(JSON.parse(localStorage.getItem("hideProjects")));
  const [hideSetupMenu, setHideSetupMenu] = useState(JSON.parse(localStorage.getItem("hideSetupMenu")));
  const [hideWorkspaceMenu, setHideWorkspaceMenu] = useState(JSON.parse(localStorage.getItem("hideWorkspaceMenu")))
  const [requestsMenu, setRequestsMenu] = useState(location.pathname.includes("dashboard") || false);

  useEffect(() => {
    localStorage.setItem("hideProjects", hideProjects);
    localStorage.setItem("hideProjectMenu", hideProjectMenu);
    localStorage.setItem("hideSetupMenu", hideSetupMenu);
    localStorage.setItem("hideWorkspaceMenu", hideWorkspaceMenu);
  }, [hideProjects, hideProjectMenu, hideSetupMenu, hideWorkspaceMenu])

  useEffect(() => {
    const interval = setInterval(() => {
      setStep(2)
    }, 4000);
  
    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    fetchProjects();
    fetchChatroomsUnread();
    fetchTimesheets();
    // setLoaded(true)
  }, [match.params.organization_id]);

  useEffect(() => {
    // Load data from LocalForage when the component mounts
    localForage.getItem(`${match.params.organization_id}-projects`).then(function (value) {
      if (value) {
        setProjects(value.projects);
        setPrivateProjects(value.private_projects);
        setScreenshotProject(value.screenshot_project);
        setTesters(value.testers);
        setLoaded(true);
      }
    }).catch(function(err) {
      console.error('Error loading data from LocalForage:', err);
      // Fetch projects from the server in case of an error
      fetchProjects();
    });
  }, []); 

  const fetchChatroomsUnread = () => {
    axios.get(`/api/o/${match.params.organization_id}/all_unread_messages.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setUnreadMessages(response.data.unread_messages)
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      // always executed
    });
  }

  const fetchProjects = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setProjects(response.data.projects);
        setPrivateProjects(response.data.private_projects);
        setScreenshotProject(response.data.screenshot_project);
        setTesters(response.data.testers)
        if(response.data.projects.length == 0){
          // notice("You don't have access to this workspace"); 
          // window.location = "/"
        }
        setLoaded(true);

        localForage.setItem(`${match.params.organization_id}-projects`, response.data).then(function (value) {
          console.log(value);
        }).catch(function(err) {
          // This code runs if there were any errors
          console.log(err);
        });
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      // always executed
    });
  }
  window.fetchProjects = fetchProjects;

  const fetchProjectsAsync = async () => {
    await axios.get(`/api/o/${organizationAuthorizations.organization.token}/projects.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setProjects(response.data.projects);
        setPrivateProjects(response.data.private_projects);
        setScreenshotProject(response.data.screenshot_project);
        refetchSubscriptions();
        setLoaded(true);
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      // always executed
    });
  }
  window.fetchProjectsAsync = fetchProjectsAsync;

  const fetchTimesheets = () => {
    axios.get(`/api/o/${match.params.organization_id}/active_timesheets.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setTimesheets(response.data.timesheets);
        // notice("Successfully fetched timesheets")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  };

  const fetchCheckoutUrl = () => {
    setDisabled(true)
    axios.post(`/api/o/${match.params.organization_id}/stripe_checkout?redirect_path=${window.location.href}&tolt_referral=${window.tolt_referral}`)
    .then(function(response){
      console.log(response);
      window.location.replace(response.data.payment_url);
      refetchData();
    })
    .catch(function(error){
      console.log(error)
      // notice("An error occured")
    })
    .then(function () {
      // setDisabled(false)
    });
  };

  

  const timesheets_list = timesheets.filter(item => !item.finished).map(timesheet => {
    return(
      <ActiveTimer key={timesheet.updated_at} timesheet={timesheet} refetchData={fetchTimesheets}/>
    )
  })

  const private_projects_list = privateProjects.map((project, index) => {
    return (
      <Project key={project.token} fetchProjects={fetchProjects} project={project} privateProject={true} index={index}/>
    )
  })

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    // Get the source and destination indexes
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
  
    // Reorder the projects list in the state (optional)
    const newProjects = [...projects];
    const [reorderedProject] = newProjects.splice(sourceIndex, 1);
    newProjects.splice(destinationIndex, 0, reorderedProject);
    setProjects(newProjects);

    // Create an array to store the data to be sent in the POST request
    const updatedProjects = projects.map((project, index) => ({
      title: project.title,
      token: project.token,
      newPosition: index === destinationIndex ? sourceIndex + 1 : index + 1,
    }));
  
    // Make a POST request to update project positions
    const organizationId = match.params.organization_id; // Replace with your actual organization ID
    const apiUrl = `/api/o/${organizationId}/projects/reorder`;
  
    axios
      .post(apiUrl, newProjects)
      .then((response) => {
        // Handle the successful response
        console.log('Project positions updated successfully', response.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the POST request
        console.error('Error updating project positions', error);
      });
  };  

  return(
    organizationAuthorizations != null &&
    <React.Fragment>
      <ProjectContext.Provider value={{fetchTimesheets, timesheets, testers, electron, projects, fetchChatroomsUnread, fetchProjects}}>
        <Modal open={openSubscriptionModal} onClose={() => setOpenSubscriptionModal(false)} className="modal-body-white" classNames={{modal: 'width-1000-modal'}} center>
          <Premium organization_id={organizationAuthorizations.organization.token}/>
        </Modal>

        <div className="container-fluid no-mobile-padding display-row platform-wrapper">
          { showSidenav && 
            <>
              {/* <SideNavigationOrganizations /> */}
              <div className="display-column sidebar-background-color" style={{minWidth: `260px`, width: "260px", transition: "width 200ms", paddingTop: "15px"}}>
                {loaded &&
                  <div id="filterChatrooms">
                    <Organizations currentUser={currentUser} organizationAuthorizations={organizationAuthorizations}/>
                    <Notifications />
                    {timesheets_list}
                  </div>
                  }
                
                <div style={{overflow: "auto"}}>
                  { loaded ? (
                    <div id="filterChatrooms" style={{overflow: "auto", height: "100%", paddingBottom: "150px"}}>
                      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
                      <React.Fragment>
                        <div className="mt-5 animate fadeIn">
                          {(organizationAuthorizations.organization.organization_user || organizationAuthorizations.project_user) && 
                            <React.Fragment>
                              <label onClick={() => setHideWorkspaceMenu(!hideWorkspaceMenu)} className="sidebar-text-color sidebar-label">
                                {t("Workspace")}
                                <i class={`sidebar-text-color fas ml-5 ${hideWorkspaceMenu ? "fa-caret-right" : "fa-caret-down"}`}></i>
                              </label>
                              
                              {!hideWorkspaceMenu && 
                                <div className="animated fadeIn">
                                  <li className="daas-platform-links-li animate color-1">
                                    <a className="daas-platform-links-a sidebar-text-color display-flex space-between align-content-center" onClick={() => setRequestsMenu(!requestsMenu)} activeClassName="sidebar-background-color">
                                      <div className="display-flex align-content-center" style={{marginLeft: -2}}>
                                        {/* <i class="fal fa-layer-group mr-10"></i> */}
                                        <i className="mr-10 opacity-6">
                                          <KanbanBoardIcon size={16} color="var(--text-color)" />
                                        </i>
                                        {t("Requests")}
                                      </div>
                                        <i class={`sidebar-text-color fas ${requestsMenu ? "fa-caret-down" : "fa-caret-right"}`}></i>
                                    </a>
                                  </li>

                                  {requestsMenu && 
                                    <div className="animated fadeIn">
                                      <li className="daas-platform-links-li animated fadeInUp fast-delay-0 sidebar-text-color side-nav-submenu-li">
                                        <NavLink activeClassName="background-active" to={`/o/${match.params.organization_id}/dashboard`} exact className="daas-platform-links-a sidebar-text-color side-nav-submenu" data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">SHIFT</span> + <span class="tooltip-keybind">r</span></div>`}>
                                          {t("All requests")}
                                        </NavLink>
                                      </li>
    
                                      <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-1">
                                        <NavLink to={`/o/${match.params.organization_id}/dashboard/my-tasks`} activeClassName="background-active" className="daas-platform-links-a sidebar-text-color side-nav-submenu" data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">SHIFT</span> + <span class="tooltip-keybind">m</span></div>`}>
                                          {t("Assigned to me")}
                                        </NavLink>
                                      </li>
                                    </div>
                                  }
    
                                  <li className="daas-platform-links-li animate color-1">
                                      <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/dashboard/analytics`} className="daas-platform-links-a sidebar-text-color"  data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">SHIFT</span> + <span class="tooltip-keybind">a</span></div>`}>
                                        <i class="fal fa-chart-bar mr-10"></i> {t("Analytics")}
                                    </NavLink>
                                  </li>
    
                                  {testers.some(tester => tester.kind === 'themes') && 
                                    <li className="daas-platform-links-li animate color-1">
                                      <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/themes`} className="daas-platform-links-a sidebar-text-color">
                                        <i class="fal fa-browser mr-10"></i> {t("Website")}
                                      </NavLink>
                                    </li>
                                  }
    
                                  {testers.some(tester => tester.kind === 'chatroom') && 
                                    <li className="daas-platform-links-li animate color-1">
                                      <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/chatrooms`} className="daas-platform-links-a sidebar-text-color" data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">SHIFT</span> + <span class="tooltip-keybind">i</span></div>`}>
                                        <i class="fal fa-inbox mr-10"></i> {t("Inbox")}
                                        {unreadMessages > 0 && 
                                          <span className="notification-dot">{unreadMessages}</span>
                                        }
                                      </NavLink>
                                    </li>
                                  }
    
                                  {(organizationAuthorizations.organization.organization_user) && 
                                    <li className="daas-platform-links-li animate color-1">
                                      <NavLink activeClassName="background-active dark-background-6" to={`/o/${match.params.organization_id}/dashboard/people`} className="daas-platform-links-a sidebar-text-color" data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">SHIFT</span> + <span class="tooltip-keybind">p</span></div>`}>
                                        <i class="fal fa-users mr-10"></i> {t("People")}
                                      </NavLink>
                                    </li>
                                  }
                                </div>
                              }


                              <label onClick={() => setHideSetupMenu(!hideSetupMenu)} className="sidebar-text-color opacity-4 font-12 cursor-pointer opacity-10-hover sidebar-label">
                                Setup <i class={`sidebar-text-color fas ml-5 ${hideSetupMenu ? "fa-caret-right" : "fa-caret-down"}`}></i>
                              </label>
                              
                              {!hideSetupMenu && 
                                <div className="animated fadeIn">
                                  <li className="daas-platform-links-li animate color-1">
                                    <NavLink activeClassName="background-active dark-background-6" to={`/o/${match.params.organization_id}/services`} className="daas-platform-links-a sidebar-text-color">
                                      <i class="fal fa-industry-alt mr-10"></i> {t("My Services")}
                                    </NavLink>
                                  </li>

                                  <li className="daas-platform-links-li animate color-1">
                                    <NavLink to={`/o/${match.params.organization_id}/settings/company`} activeClassName="background-active dark-background-6" className="daas-platform-links-a sidebar-text-color">
                                      <i class="fal fa-paint-brush mr-10"></i> {t("Customizations")}
                                    </NavLink>
                                  </li>

                                  <li className="daas-platform-links-li animate color-1">
                                    <a className="daas-platform-links-a sidebar-text-color display-flex space-between align-content-center" onClick={() => setHideProjectMenu(!hideProjectMenu)}>
                                      <div className="display-flex align-content-center">
                                        <i class="fal fa-user-cog opacity-6" style={{marginRight: "8px"}}></i>{t("Workflow setup")}
                                      </div>
                                      <i class={`sidebar-text-color fas ${hideProjectMenu ? "fa-caret-down" : "fa-caret-right"}`}></i>
                                    </a>
                                  </li>

                                  {hideProjectMenu && 
                                    <div className="animated fadeIn">
                                      <li className="daas-platform-links-li animated fadeInUp fast-delay-0 sidebar-text-color side-nav-submenu-li">
                                        <NavLink activeClassName="background-active" to={`/o/${match.params.organization_id}/settings/client-onboarding`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
                                          {t("Client onboarding")}
                                        </NavLink>
                                      </li>
      
                                      <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-1">
                                        <NavLink to={`/o/${match.params.organization_id}/referrals`} activeClassName="background-1 dark-background-6" className="daas-platform-links-a sidebar-text-color side-nav-submenu">
                                          {t("Referral Program")}
                                        </NavLink>
                                      </li>
      
                                      <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-2">
                                        <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/task_forms`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
                                          {t('Task forms')}
                                        </NavLink>
                                      </li>
      
                                      {testers.some(tester => tester.kind === 'Task reviews') && 
                                        <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-3">
                                          <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/reviews`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
                                            {t("Task reviews")}
                                          </NavLink>
                                        </li>
                                      }
      
                                      <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-3">
                                        <NavLink activeClassName="background-active"  to={`/o/${match.params.organization_id}/task_boards`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
                                          {t("Taskboard templates")}
                                        </NavLink>
                                      </li>
                                    </div>
                                  }

                                  {organizationAuthorizations.organization.organization_user && 
                                    <li className="daas-platform-links-li animate color-1">
                                      <a onClick={() => setOpenPlugins(true)} activeClassName="background-active dark-background-6" className="daas-platform-links-a sidebar-text-color">
                                        <i class="far fa-shapes mr-10"></i> {t("Plugins")}
                                      </a>

                                      <Modal open={openPlugins} onClose={() => setOpenPlugins(false)} className="modal-body-white" classNames={{modal: 'width-1000'}} center>
                                        <PluginsIndex closeModal={() => setOpenPlugins(false)}/>
                                      </Modal>
                                    </li>
                                  }

                                </div>
                              }
                            </React.Fragment>
                          }

                          {organizationAuthorizations.project_user && 
                            <li className="daas-platform-links-li animate sidebar-text-color">
                              <NavLink activeClassName="background-active dark-background-6"  to={`/o/${match.params.organization_id}/${organizationAuthorizations.nonfintech_user ? "projects" : "dashboard"}`} className="daas-platform-links-a sidebar-text-color">
                                <i class="fal fa-tachometer-alt-fastest mr-10"></i> {t("Dashboard")}
                              </NavLink>
                            </li>
                          }
                          
                          <label className="sidebar-text-color project-side-navigation-label hover-parent-opacity sidebar-text-color font-12 mb-8 opacity-1">
                            <div className="display-flex align-items-center cursor-pointer opacity-4 opacity-10-hover" onClick={() => setHideProjects(!hideProjects)}>
                              <div>
                                {t("Projects")}
                              </div>
                              <i class={`sidebar-text-color fas ml-5 ${hideProjects ? "fa-caret-right" : "fa-caret-down"}`}></i>
                            </div>

                            <div>
                              <div class="btn-group hover-child-opacity">
                                <a class="sidebar-text-color dropdown-toggle btn btn-small background-hover" data-toggle="dropdown">
                                  <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <ul class="dropdown-menu animated fadeInUp pull-right" role="menu" style={{minWidth: "unset"}}>
                                  <li><a onClick={() => setOpenArchived(true)}><i class="fal fa-trash mr-5"></i>{t("Archives")}</a></li>
                                  <Modal open={openArchived} onClose={() => setOpenArchived(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                                    <Archives refetchData={fetchProjects}/>
                                  </Modal>
                                </ul>
                              </div>

                              {organizationAuthorizations.organization.organization_user && 
                                <React.Fragment>
                                  <i date-tip="Add Project" onClick={() => {organizationAuthorizations.allow_upload ? onOpenModal() : setOpenSubscriptionModal(true)}}  class="fas fa-plus-circle opacity-6 opacity-10-hover animate sidebar-text-color cursor-pointer btn btn-small background-hover mr-5"></i> 
                                  <Modal open={open} onClose={onCloseModal} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                                    <NewProject organization_id={organizationAuthorizations.organization.token} onCloseModal={onCloseModal} refetchData={fetchProjects}/>
                                  </Modal>
                                </React.Fragment>
                              }
                            </div>
                          </label>

                          {!hideProjects && 
                            <div className="animated fadeIn">
                              {/* {projects_list} */}
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="projectList">
                                  {(provided) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft: 0, listStyle: "none"}}>
                                      {projects.map((project, index) => (
                                        <Draggable key={project.token} draggableId={`project-${project.token}`} index={index}>
                                          {(provided) => (
                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                              <Project key={project.token} fetchProjects={fetchProjects} project={project} privateProject={false} index={index}/>
                                            </li>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              {private_projects_list}
                            </div>
                          }  
                          </div>
                        </React.Fragment>
                      </div>
                    ):(
                      <ChatroomSkeleton />
                    )}
                  </div>
                <div>
                  <Checklist />
                </div>

                {(organizationAuthorizations.organization.organization_user && !organizationAuthorizations.organization_subscribed.subscribed && !organizationAuthorizations.organization_subscribed.tier === null) && 
                  <a type="button" class="btn btn-primary btn-small side-nav-upgrade-button" style={{background: "none"}} data-toggle="dropdown" onClick={fetchCheckoutUrl}>
                    ✨ Upgrade ✨
                  </a>
                }
              </div>
            </>
          }


          <div className="flex project-wrapper">
            {/* toggle projects sidebar  */}
              {!showSidenav ? <div className="display-center cp background-2 toggle-sidenavigation" style={{position: "absolute"}} onClick={() => setShowSidenav(true)}><i class="font-12 fas fa-chevron-double-right color-4"></i></div> : <div className="display-center cp background-3 toggle-sidenavigation toggle-position" onClick={() => setShowSidenav(false)}><i class="fas fa-chevron-double-left color-4 font-12"></i></div>}


              <div className="display-column width-100-percent">
                {(organizationAuthorizations.organization_subscribed.days_left != null) && 
                  <TrialEnding organizationAuthorizations={organizationAuthorizations} upgrade={fetchCheckoutUrl}/>
                }

                {!organizationAuthorizations.organization_subscribed.allow_access && 
                  <div className="please-upgrade-wrapper">
                    <div className="subscription-required-wrapper">
                      {step === 1 && 
                        <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={lottieConfetti} />
                      }
                      {step === 2 &&
                        <div className="animated fadeIn slow-1">
                          <h1 className="margin-none">🥳</h1>
                          <h3 className="font-weight-600 mt-0">{t("Congrats on getting a client!")}</h3>
                          <div className="animated fadeIn delay-3">
                            <p className="opacity-6">{t("Now that you have a client, it's time to upgrade to continue using Queue or remove your project containing your client.")}</p>
                            <p className="font-12 opacity-6">{t("Don't worry, your client still has full access.")}</p>
                            <a disabled={disabled} className="btn btn-primary animated delay-4" onClick={()=> fetchCheckoutUrl()}><i class="fal fa-rocket mr-8"></i>Upgrade</a>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className={!organizationAuthorizations.organization_subscribed.allow_access ? "blur-background" : ""}>
                  <Switch>
                    <Route exact path={`/o/:organization_id/projects`} render={() => <Home/> } />
                    <Route path={`/o/:organization_id/dashboard`} render={() => <Dashboard projectAuthorizations={organizationAuthorizations}/> } />
                    {/* <Route exact path={`/o/:organization_id/analytics`} render={() => <Analytics projectAuthorizations={organizationAuthorizations}/> } /> */}
                    <Route path="/o/:organization_id/services" component={ServicesWrapper}/>
                    <Route exact path="/o/:organization_id/task_forms" component={TaskFormsIndex}/>
                    <Route exact path="/o/:organization_id/task_boards" component={TaskBoards}/>
                    <Route exact path="/o/:organization_id/settings/client-onboarding" component={ClientOnboardingsNew}/>
                    <Route path="/o/:organization_id/settings" component={Settings}/>
                    <Route path="/o/:organization_id/referrals" component={ReferralRouter}/>
                    <Route path="/o/:organization_id/chatrooms" component={ChatroomsWrapper}/>
                    <Route path="/o/:organization_id/reviews" component={Reviews}/>
                    {/* <Route path="/o/:organization_id/people" component={People}/> */}
                    <Route exact path="/o/:organization_id/themes" component={Themes}/>
                    <Suspense fallback={<div></div>}>
                      <Route path={`/o/:organization_id/projects/:project_id`} render={() => <LazyProjectWrapper fetchProjects={fetchProjects}/>} />
                    </Suspense>
                  </Switch>
                </div>
              </div>

          </div>
        </div>
      </ProjectContext.Provider>
    </React.Fragment>
  )
}

export default Projects;