import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Skeleton from "react-loading-skeleton"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {UserContext} from "../Shared/UserContext.js";
import _ from 'lodash';
import ProjectUser from "./ProjectUser.js";
import OrganizationUser from "./OrganizationUser.js";
import ClientUser from "./ClientUser.js";

const People = (props) => {
  const currentUser = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false)
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([])
  const [clients, setClients] = useState([]); 
  const [organization, setOrganization] =  useState(); 
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    fetchPeople();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  },[copied])

  const fetchPeople = () => {
    axios.get(`/api/o/${match.params.organization_id}/people.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
        setProjectUsers(response.data.project_users);
        setClients(response.data.clients);
        setOrganization(response.data.organization)
        setCompanies(response.data.companies);
      } else {
        notice(response.data.error);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setLoaded(true)
    });
  }

  // organization_users
  const organization_users_list =  organizationUsers.map(user => {
    return(
      <OrganizationUser user={user} refetchData={fetchPeople} />
    )
  })

  // clients
  const clients_list = clients.map(client => {
    return(
      <ClientUser client={client} setClients={setClients} refetchData={fetchPeople} companies={companies}/>
    )
  })

  const project_users_list = projectUsers.map(project_user => {
    return(
      <ProjectUser project_user={project_user} projectUsers={projectUsers} setProjectUsers={setProjectUsers} refetchData={fetchPeople}/>
    )
  })

  return(
    loaded && 
    <React.Fragment>
      <title>People</title>
      <div className="mt-25 mb-25">
        <div className="row">
          <div className="table-overflow" style={{marginBottom: "30px"}}>
            <div className="default-padding border-all border-radius background-3">
              <h4 className="people-title color-black-always">
                Workspace members
                <CopyToClipboard text={`https://${location.host}/organizations/${organization.share_token}/invite?redirect=/o/${match.params.organization_id}`} onCopy={() => {setCopied(true); notice("Copied")}}>
                  <a className={`people-invite-link ${copied ? "color-green" : "color-4"}`}><i class="fas fa-link" style={{marginRight: "5px"}}></i> Invite team</a>
                </CopyToClipboard>
              </h4>
              <p className="opacity-4 font-12 pl-15" style={{marginTop: "-5px"}}>
                Workspace members have access to all projects and client details.  <a href="https://guides.usequeue.com/project-collaboration/invite-client-and-team#how-workspace-member-and-collaborators-work" target="_blank">Video guide</a>
              </p>

              {loaded ? (
                <table class="table">
                  <thead>
                    <tr>
                      <th className="color-black-always opacity-4" scope="col">Name</th>
                      <th className="color-black-always opacity-4" scope="col">Email</th>
                      <th scope="col">Hourly rate</th>
                      <th scope="col">Job title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {organization_users_list}
                  </tbody>
                </table>
              ):(
                <div className="default-padding">
                  <Skeleton className="skeleton" count={3}/>
                </div>
              )}
            </div>
          </div>

          {/* Freelancers  */}
          {!currentUser.is_client &&
            <div className="table-overflow" style={{marginBottom: "30px"}}>
              <div className="default-padding border-all border-radius background-3">
                <h4 className="people-title">Collaborators</h4>
                <p className="opacity-4 font-12 pl-15" style={{marginTop: "-5px"}}>
                  Collaborators have access to only the project you invite them to and has no access to the clients purchase details, and can be invited in High Privacy mode, making them hidden from clients. <a href="https://guides.usequeue.com/project-collaboration/invite-client-and-team#how-workspace-member-and-collaborators-work" target="_blank">Video guide</a>
                </p>
                {
                  loaded ? (
                    <table class="table">
                      <thead>
                      <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Hourly rate</th>
                          <th scope="col">Job title</th>
                          <th scope="col">Project</th>
                        </tr>
                      </thead>
                      <tbody>
                        {project_users_list}
                      </tbody>
                    </table>
                  ):(
                    <div className="default-padding">
                      <Skeleton className="skeleton" count={3}/>
                    </div>
                  )
                }
                
              </div>
            </div>
          }

          {/* Clients */}
          <div className="table-overflow" style={{marginBottom: "30px"}}>
            <div className="default-padding border-all border-radius background-3">
              <h4 className="people-title">Clients</h4>
              <p className="opacity-4 font-12 pl-15" style={{marginTop: "-5px"}}>
                Clients will have access to only the project they've been invited to or created by purchasing a service. <a href="https://guides.usequeue.com/project-collaboration/invite-client-and-team#how-to-invite-a-client-to-a-project" target="_blank">Video guide</a>
              </p>
              {
                loaded ? (
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Project</th>
                        <th scope="col">Company</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients_list}
                    </tbody>
                  </table>
                ):(
                  <div className="default-padding">
                    <Skeleton className="skeleton" count={3}/>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default People