import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Form from "./Form.js";
import UnsubscribeSettings from "./UnsubscribeSettings.js";

const ServiceDropdown = ({service, refetchData, addon}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUnsubscribeSettings, setOpenUnsubscribeSettings] = useState(false);
  
  const handleDelete = (evt) => {
    if(confirm("Are you sure you want to delete this service?")){
      evt.preventDefault();
      setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/services/${service.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
        setDisabled(false);
      });
    }
  }

  return(
    <div class="btn-group">
      <a type="button" class="btn btn-small color-1 dropdown-toggle opacity-6 opacity-10-hover" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="far fa-ellipsis-v"></i>
      </a>
      <ul class="dropdown-menu pull-right animated fadeInUp">
        <li>
          <a onClick={() => setOpen(true)}><i class="fal fa-pencil"></i>Edit</a>
          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
            <Form service={service} refetchData={refetchData} edit={true} closeModal={() => setOpen(false)} addon={addon}/>
          </Modal>
        </li>

        <li>
          <a onClick={() => setOpenUnsubscribeSettings(true)}><i class="fal fa-gift"></i>Retention settings</a>
          <Modal open={openUnsubscribeSettings} onClose={() => setOpenUnsubscribeSettings(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <UnsubscribeSettings service={service} refetchData={refetchData} edit={true} closeModal={() => setOpenUnsubscribeSettings(false)}/>
          </Modal>
        </li>

        {service.allow_delete && 
          <li>
            <a onClick={handleDelete} disabled={disabled}><i class="fal fa-trash-alt"></i>Delete</a>
          </li>
        }
      </ul>
    </div>
  )
}

export default ServiceDropdown;