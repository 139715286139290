import React, {useState, useEffect} from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Index from "./Index.js";
import Show from "./Show.js";
import ChatroomList from "./ChatroomList.js";
import EmailedTaskShow from "../EmailedTasks/EmailedTaskShow.js";

const ChatroomsWrapper = ({}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true)
  },[match.params.organization_id]);

  return(
    loaded && 
    <React.Fragment>
      <title>Inbox</title>
      <div className="row" style={{height: "100vh"}}>
        <div className="col-12 col-md-3 col-lg-3 border-right background-1">
          <ChatroomList />
        </div>

        <div className="col-12 col-md-9 col-lg-9">
          <Switch>
            <Route exact path="/o/:organization_id/chatrooms" render={() => <Index />} />
            <Route exact path="/o/:organization_id/chatrooms/:chatroom_id" render={() => <Show chatroomWrapperClass={"chatroom-show-messages-wrapper"} showHeader={true}/>} />
            <Route exact path="/o/:organization_id/chatrooms/emailed_tasks/:emailed_task_id" render={() => <EmailedTaskShow />} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChatroomsWrapper