import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import FullCardSubtask from "./FullCard.js";
import { useContextMenu } from "react-contexify";
import { useTranslation } from 'react-i18next';
import TaskDropdown from "./TaskForms/TaskDropdown.js";
import StageIcon from "../../../Shared/StageIcon.js";
import DatePicker from "react-datepicker";

const SubTask = ({subtask, columns, refetchData, organizationUser, project_id, fetchTask, setSubtasks, openSubtaskToken, setOpenSubtaskToken}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [openSubtask, setOpenSubtask] = useState(false);
  const { show } = useContextMenu({
    id: subtask.token
  });
  function displayMenu(e){
    e.stopPropagation();
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }
  const { show: showContextMenu } = useContextMenu();

  useEffect(() => {
    if (openSubtaskToken === subtask.token) {
      setOpenSubtask(true);
      setOpenSubtaskToken(null); // Reset the token after opening
    }
  }, [openSubtaskToken, subtask.token]);

  const moveTask = (column_id) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}/update_position`, {
      column_id: column_id,
      position: 0,
    })
    .then(function (response) {
      setSubtasks(prevSubtasks => prevSubtasks.map(st => 
        st.token === response.data.task_data.token ? response.data.task_data : st
      ));
      refetchData();
    })
    .catch(function (error) {
      console.log(error);
      // Handle the error as needed
    });
  }

  const columns_list = columns.filter(column => column.token !== subtask.column.token).map(column => (
    <li key={column.token}>
      <a className="stage-dropdown-a" onClick={() => moveTask(column.token)}>
        <StageIcon stage={column.stage}/>{column.title}
      </a>
    </li>
  ));


  const handleDeadlineChange = (date) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}`, {
      deadline: date
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setSubtasks(prevSubtasks => prevSubtasks.map(st => 
          st.token === response.data.task_data.token ? response.data.task_data : st
        ));
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${subtask.token}/mark_finished`, {
      finished: !subtask.finished
    })
    .then(function(response){
      if(response.data.success){
        setSubtasks(prevSubtasks => prevSubtasks.map(st => 
          st.token === response.data.task_data.token ? response.data.task_data : st
        ));
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
      reportError(`File: SubTask.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .finally(function () {
      setDisabled(false);
    });
  }

  const subtask_users = subtask.users.map(user => {
    return(
      <>
        <img data-tip={user.username} src={user.avatar} className="avatar-more" />
      </>
    )
  })

  return(
    <React.Fragment>
        <div data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">J or K</span></div>`} className="display-flex align-items-center col-5 pr-15 font-12 pl-15 background-hover border-radius cursor-pointer padding-8" onClick={() => {
          setOpenSubtask(true); 
          // history.push(`?subtask_id=${subtask.token}`)
        }}>
          <div onClick={markFinished}>
            {subtask.finished ? (
              <i className="far fa-check-square mr-8 cursor-pointer color-green"></i>
            ) : (
              <i className="far fa-square mr-8 cursor-pointer"></i>
            )}
          </div>
        
          {subtask.private && 
            <i className="fal fa-lock mr-5"></i>
          }

          <div className="opacity-4 opacity-10-hover cursor-pointer truncate mr-10">
            {subtask.title}
          </div>

          {(subtask?.subtasks?.length > 0 && subtask?.subtasks_finished != null ) &&
            <>
              <span data-tip="View subtasks"  onClick={() => setOpenSubtask(true)} className={`badge ${(subtask.subtasks.length / subtask.subtasks_finished.length) === 1 ? "badge-green" : ""} background-hover background-3 cursor-pointer font-weight-500 task-deadline-wrapper`}>
                {((subtask.subtasks.length / subtask.subtasks_finished.length) === 1) ? (
                  <>
                    <i class="far fa-check-square mr-5"></i>
                  </>
                ):(
                  <>
                    <i class="far fa-square mr-5"></i>
                  </>
                )}
                {subtask.subtasks_finished.length}/{subtask.subtasks.length}
              </span>
            </>
          }
        </div>

        <div className="col-2">
          <DatePicker
            selected={subtask.deadline}
            onChange={(date) => handleDeadlineChange(date)}
            className="form-control-small background-none border-all-hover"
            placeholderText="Set Due Date"
            popperPlacement="top-end"
          />
        </div>

        <div className="display-flex justify-content-between align-items-center col-4">
          <div class="btn-group">
            <button type="button" class="btn btn-small btn-primary-outline border-none dropdown-toggle background-hover display-flex align-items-center" data-toggle="dropdown" >
              <StageIcon size={15} stage={subtask.column.stage}/>{subtask.column.title} <i class="far fa-angle-down ml-5"></i>
            </button>

            <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
              {columns_list}
            </ul>
          </div>

          <div className="subtasks-list-users">
            {subtask.users.length > 1 ? (
              <>
                <div data-tip={subtask.users.map(user => user.username).join(', ')} className="avatar-more border-all background-3 text-center" style={{zIndex: 1, width: 33, height: 33}}>+{subtask.users.length}</div>
                <img data-tip={subtask.users[0].username} src={subtask.users[0].avatar} className="avatar-more ml-15-negative"/>
              </>
            ) : (
              <>
                {subtask_users}
              </>
            )
            }
          </div>

          {/* Dropdown */}
          <TaskDropdown task={subtask} refetchData={refetchData} columns={columns} project_id={project_id} organizationUser={organizationUser} displayMenu={displayMenu} subtask={true} setArray={setSubtasks}/>
        </div>

      <Modal focusTrapped={false} open={openSubtask} onClose={() => {
          setOpenSubtask(false);
          // Remove the subtask_id from the URL when closing the modal
          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('subtask_id');
          history.replace({ search: searchParams.toString() });
        }}  className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <FullCardSubtask task_id={subtask.token} refetchData={() => {refetchData(); fetchTask()}} project_id={project_id}/>
      </Modal>
    </React.Fragment>
  )
}

export default SubTask;