import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Select from 'react-select';

const ClientUser = ({client, setClients, refetchData, companies}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    // Initialize selectedUsers with existing company users
    if (client.companies) {
      const initialCompanies = client.companies.map(company => ({
        value: company.token,
        label: company.title,
      }));
      setSelectedCompanies(initialCompanies);
    }
  }, [client.companies]);

  const companyOptions = companies.map(company => ({
    value: company.token,
    label: company.title
  }));

  const handleCompanyChange = (newSelectedOptions) => {
    // Find added and removed companies
    const addedCompanies = newSelectedOptions.filter(
      option => !selectedCompanies.some(selected => selected.value === option.value)
    );
    const removedCompanies = selectedCompanies.filter(
      selected => !newSelectedOptions.some(option => option.value === selected.value)
    );

    // Call handleCompanyAdded for each added company
    addedCompanies.forEach(company => handleCompanyAdded(company));

    // Call handleCompanyRemoved for each removed company
    removedCompanies.forEach(company => handleCompanyRemoved(company));

    // Update the state with the new selection
    setSelectedCompanies(newSelectedOptions);
  };

  const removeClient = () => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/api/o/${match.params.organization_id}/projects/${client.project.token}/clients/${client.token}`)
      .then(function(response){
        if(self){
          // fetchPeople();
          setClients(prevState => _.reject(prevState, { token: client.token }));
        } else {
          // fetchPeople();
        }
      })
    }
  };

  const handleCompanyAdded = (company) => {
    axios.post(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users.json?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is added
  }

  const handleCompanyRemoved = (company) => {
    console.log(`User removed: ${company.label} (ID: ${company.value})`);
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users/${client.user.id}?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is removed
  }

  const handleOptionClick = (option, event) => {
    if (option.value !== 'add_company') {
      // Open a modal or perform any action for existing companies
      console.log(`Clicked on company: ${option.label}`);
      // You can add your modal opening logic here
    }
  };

  const user_companies_list = selectedCompanies.map(company => (
    <div className="badge border-all color-1 background-hover">{company.label}</div>
  ));


  return(
    <React.Fragment>
      <tr>
        <td><img src={client.user.avatar} className="people-avatar"/> {client.user.username}</td>
        <td>{client.user.email}</td>
        <td><Link className="badge border-all color-1 background-hover" to={`/o/${match.params.organization_id}/projects/${client.project.token}/tasks`} >{client.project.title}</Link></td>
        <td>
          {user_companies_list}
        </td>
        <td className="text-right">
          <a onClick={removeClient} className="color-red opacity-10-hover btn btn-small background-hover"><i class="fal fa-times"></i></a>  
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ClientUser;