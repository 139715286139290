import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import {TaskboardContext} from "../Shared/TaskboardContext.js";

const TaskLabels = ({label, task, refetchData, setArray}) => {
  const {setLabels} = useContext(TaskboardContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  // create task label
  const createTaskLabel = () => {
    axios.post(`/api/portal/projects/${match.params.project_id}/tasks/${task.token}/task_labels`,{
      label_id: label.token
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        if(setArray){
          setArray(prevState => [...prevState, response.data.task_label]);
        }
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  // delete label
  const removeLabel = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation
    axios.delete(`/api/o/${match.params.organization_id}/labels/${label.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setLabels(prevState => _.reject(prevState, { token: label.token }));
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <Item closeOnClick={false} onClick={createTaskLabel}>
        <div className="display-flex align-items-center justify-content-between label-item-hover-parent" style={{width: "100%"}}>
          <div className="display-flex align-items-center">
            <span className="label-color" style={{background: `#${label.color}`}}></span>
            {label.title}
          </div>

          <i className="fal fa-trash ml-15 label-item-hover-child color-red-hover animate" onClick={removeLabel}></i>
        </div>
      </Item>
    </React.Fragment>
  )
}

export default TaskLabels;