import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import axios from "axios";
import { Modal } from 'react-responsive-modal';
import localForage from "localforage";
import FullCard from "../Daas/Portal/Projects/FullCard.js";
import NoDeadlineTasks from "./NoDeadlineTasks.js";
import Loading from "../Shared/Loading.js";
// import "./styles.css";
import NewLabel from "../Labels/NewLabel.js";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const MyCalendar = ({organization_id, projectAuthorizations, project_id, height, users}) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [noDeadlineTasks, setNoDeadlineTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [openAddTask, setOpenAddTask] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [clientView, setClientView] = useState(typeof window !== 'undefined' && window.location.href.includes("/portal/o/"))

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const value = await localForage.getItem(`${project_id || organization_id}-tasks-calendar`);
        if(value){
          setEvents(value.tasks);
          setNoDeadlineTasks(value.no_deadline_tasks);
          }
        await fetchTasks(signal); // Fetch new data
      } catch (error) {
        console.error('Error fetching and updating data:', error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function: cancel the ongoing fetch request
      controller.abort();
    };
  }, [project_id]);

  const fetchTasks = async (signal) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/o/${organization_id}/dashboard_tasks.json${project_id ? `?project_id=${project_id}` : '' }`, { signal });
      if (response.data.success) {
        const tasksWithDates = response.data.tasks.map(task => ({
          ...task,
          start: new Date(task.start), // Convert start date string to Date object
          end: new Date(task.end),     // Convert end date string to Date object
        }));
        setEvents(tasksWithDates);
        setNoDeadlineTasks(response.data.no_deadline_tasks);

        await localForage.setItem(`${project_id || organization_id}-tasks-calendar`, response.data);
        setLoading(false)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    setStartDate(start)
    setEndDate(end)
    setOpenAddTask(true)
  };

  const handleSelectEvent = (event) => {
    setOpen(true);
    setSelectedTask(event);
    // alert(`Event: ${event.title}`);
    console.log(event);
    // Your custom function logic here
  };

  const moveEvent = ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };

    const nextEvents = events.map((existingEvent) =>
      existingEvent.title === event.title ? updatedEvent : existingEvent
    );

    setEvents(nextEvents);
    updateEvent(start, end, event.token, event.project_id)
  };

  const updateEvent = (startDate, deadline, task_id, projectId) => {
    axios.put(`/api/portal/projects/${projectId}/tasks/${task_id}`, {
      start_date: startDate,
      deadline: deadline
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchTasks();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
    });
  }

  const resizeEvent = ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };
    const nextEvents = events.map((existingEvent) =>
      existingEvent.title === event.title ? updatedEvent : existingEvent
    );
    setEvents(nextEvents);

    updateEvent(start, end, event.token, event.project_id)
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '#3174ad'; // Default color

    // Set color based on priority
    switch (event.priority) {
      case 'High':
        backgroundColor = '#dc3545'; // High priority color
        break;
      case 'Medium':
        backgroundColor = '#ffab58'; // Medium priority color
        break;
      case 'Low':
        backgroundColor = '#4169e1'; // Low priority color
        break;
      default:
        break;
    }

    // Override color for finished events
    if (event.finished) {
      backgroundColor = '#00c292'; // Gray color for finished events
    }

    return {
      style: {
        backgroundColor,
        borderRadius: '0px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block'
      }
    };
  };

  return (
    <div className="mt-15">
      {loading && 
        <Loading loading={loading} />
      }
      <DragAndDropCalendar
        localizer={localizer}
        events={events.filter(event => {
                          // new filter to exclude private cards if the URL includes "/portal/o/"
                          if (event.private && clientView) {
                            return false; // exclude this card
                          }
                          return true; // include this card
                        })}
        startAccessor="start"
        endAccessor="end"
        style={{ height: height }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onEventDrop={moveEvent}
        resizable
        onEventResize={resizeEvent} 
        eventPropGetter={eventStyleGetter} // Apply custom styles
        popup={true}
      />

      <Modal focusTrapped={false} closeOnEsc={false} open={open} onClose={() => {setOpen(false); setSelectedTask(null)}} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        {selectedTask && 
          <FullCard task_id={selectedTask.token} refetchData={fetchTasks} organizationUser={true} project_id={selectedTask.project_id}/>
        }
      </Modal>
      
      <Modal open={openAddTask} onClose={() => {setOpenAddTask(false);}} className="modal-body-white" classNames={{modal: 'modal-calendar-add-task'}} center>
        <NoDeadlineTasks refetchData={fetchTasks} tasks={noDeadlineTasks} start={startDate} end={endDate} closeModal={() => setOpenAddTask(false)} setEvents={setEvents}/>
      </Modal>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => setOpenNewLabel(false)}/>
      </Modal>
    </div>
  );
};

export default MyCalendar;
