import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Settings from "../TimeTracker/Settings.js";
import {ProjectContext} from "../Projects/ProjectContext.js";

const Index = ({closeModal}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const {fetchProjects} = useContext(ProjectContext);
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [testers, setTesters] = useState([]);
  const [openTimesheetSettings, setOpenTimesheetSettings] = useState(false);

  useEffect(() => {
    fetchTesters();
  }, []);

  const fetchTesters = () => {
    axios.get(`/testers.json?organization_id=${match.params.organization_id}`)
    .then(function(response){
      // console.log(response); 
      if(response.data.success){
        setTesters(response.data.testers);
        setLoaded(true);
      }
    })
  }

  const createTester = (kind) => {
    setDisabled(true);

    axios.post(`/testers.json?organization_id=${match.params.organization_id}&kind=${kind}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchProjects();
        closeModal();
        if(kind === "chatroom"){
          history.push(`/o/${match.params.organization_id}/chatrooms`);
        } else if (kind === "themes"){
          history.push(`/o/${match.params.organization_id}/themes`);
        } else if (kind === "Timesheets"){
          notice("Time tracking enabled. Go to a task to start tracking time.");
        }
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const deleteTester = (tester_id) => {
    setDisabled(true);

    axios.delete(`/testers/${tester_id}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchTesters();
        fetchProjects();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    loaded && 
    <React.Fragment>
      <h3 className="color-black-always font-600 mt-0">Plugins</h3>
      <h6 className="color-black-always mb-20" style={{opacity: ".5"}}>Plugins let you turn on or off certain features on Queue</h6>

      <label>Built by Queue</label>
      <div className="row ml-15-negative mb-30">
        {/* Website */}
        <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://queue-images.nyc3.cdn.digitaloceanspaces.com/icons8-template-80.png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Website Builder<span className="ml-15 badge badge-blue">Beta</span></div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Choose a website template and customize it to your business.</div>

            <div className="mt-15 width-100-percent text-right  ">
              <a href="#" target="_blank" className="btn btn-primary-outline btn-small mr-5 border-none"><i className="fas fa-play mr-8"></i>Demo</a>
              {testers.some(tester => tester.kind === "themes") ? (
                <a onClick={() => deleteTester(testers.find(tester => tester.kind === "themes").id)} className="btn btn-danger btn-small"><i className="fas fa-plus mr-8"></i>Disable</a>
                ):(
                <a onClick={() => createTester("themes")} className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Enable</a>
              )}
            </div>
          </div>
        </div>
        
        {/* Chatroom */}
        <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://queue-images.nyc3.cdn.digitaloceanspaces.com/icons8-chat-64.png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Chat widget <span className="ml-15 badge badge-blue">Beta</span></div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Install a live chat widget on your website and client portal so simplify communications.</div>

            <div className="mt-15 width-100-percent text-right  ">
              {/* <a href="#" target="_blank" className="btn btn-primary-outline btn-small mr-5 border-none"><i className="fas fa-play mr-8"></i>Demo</a> */}
              {testers.some(tester => tester.kind === "chatroom") ? (
                <a onClick={() => deleteTester(testers.find(tester => tester.kind === "chatroom").id)} className="btn btn-danger btn-small"><i className="fas fa-times mr-8"></i>Disable</a>
                ):(
                <a onClick={() => createTester("chatroom")} className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Enable</a>
              )}
            </div>
          </div>
        </div>

        <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://queue-images.nyc3.cdn.digitaloceanspaces.com/icons8-stopwatch-96.png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Time Tracker</div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Enable time tracking on tasks so you know how long it takes to finish.</div>

            <div className="mt-15 width-100-percent text-right  ">
              <a href="https://guides.usequeue.com/services/time-tracking#how-to-track-time-on-tasks-or-manually" target="_blank" className="btn btn-primary-outline btn-small mr-5 border-none"><i className="fas fa-play mr-8"></i>Demo</a>
              {testers.some(tester => tester.kind === "Timesheets") ? (
                <>
                  {/* <i class="fal fa-cog mr-15 cursor-pointer opacity-6 opacity-10-hover animate" onClick={() => setOpenTimesheetSettings(true)}></i> */}
                  <a onClick={() => deleteTester(testers.find(tester => tester.kind === "Timesheets").id)} className="btn btn-danger btn-small"><i className="fas fa-plus mr-8"></i>Disable</a>
                  <Modal open={openTimesheetSettings} onClose={() => setOpenTimesheetSettings(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                    <h4 className="color-black-always modal-title">Time Tracker settings</h4>

                    <div className="row no-margin">
                      <Settings closeModal={() => setOpenTimesheetSettings(false)} tester={testers.some(tester => tester.kind === "Timesheets")}/>
                    </div>
                  </Modal>
                </>
                ):(
                <a onClick={() => createTester("Timesheets")} className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Enable</a>
              )}
            </div>
          </div>
        </div>
        
        {/* Brand Assets */}
        {/* <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://queue.nyc3.cdn.digitaloceanspaces.com/icons8-design-80.png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Brand Assets</div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Let your clients submit their Brand Assets to keep everything organized.</div>

            <div className="mt-15 width-100-percent text-right  ">
              <a href="https://guides.usequeue.com/client-portal/brand-assets" target="_blank" className="btn btn-primary-outline btn-small mr-5 border-none"><i className="fas fa-play mr-8"></i>Demo</a>
              {testers.some(tester => tester.kind === "Brand Assets") ? (
                <>
                  <a onClick={() => deleteTester(testers.find(tester => tester.kind === "Brand Assets").id)} className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Enable</a>
                </>
                ):(
                <a onClick={() => createTester("Brand Assets")} className="btn btn-danger btn-small"><i className="fas fa-plus mr-8"></i>Disable</a>
              )}
            </div>
          </div>
        </div> */}

        {/* Task reviews */}
        <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://img.icons8.com/?size=160&id=apsEwjRsibDJ&format=png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Task Reviews</div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Get reviews from your client when they move a task to the finished column.</div>

            <div className="mt-15 width-100-percent text-right  ">
              {testers.some(tester => tester.kind === "Task reviews") ? (
                <>
                  <a onClick={() => deleteTester(testers.find(tester => tester.kind === "Task reviews").id)} className="btn btn-danger btn-small"><i className="fas fa-times mr-8"></i>Disable</a>
                </>
                ):(
                <a onClick={() => createTester("Task reviews")} className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Enable  </a>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* 3rd party */}
      <label>3rd party</label>
      <div className="row ml-15-negative">
        
        <div className="col-6 col-sm-4 padding-10">
          <div className="app-integration-box default-padding border-all border-radius box-shadow-light" style={{height: "100%"}}>
            <div className="mb-5 display-row space-between aic">
              <img src={"https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png"} className="avatar border-radius mr-8" />
              <div className="font-14 font-600 color-black-always">Slack Connect</div>
            </div>
            <div className="font-12 opacity-6 color-black-always">Add your Slack account and we'll automatically create a shared Slack Connect channel with your client.</div>

            <div className="mt-15 width-100-percent text-right  ">
              <a target="_blank" href="https://queue.featurebase.app/p/slack-connect-channel" className="btn btn-primary-outline btn-small"><i className="fas fa-plus mr-8"></i>Request plugin</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Index;