import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider } from "@apollo/client";
import client from "../replay-sync/api/client.js";
import ThemeProvider from "../replay-sync/utils/ThemeProvider.jsx";
import NotFound from "./Shared/NotFound.js";
import NewWorkspace from "./Organizations/NewWorkspace.js";
import { UserContext } from "./Shared/UserContext";
import Error from "./Layouts/Error.js";
import {CounterContext} from "./Users/CounterContext.js";
import {AppContext} from "./Shared/AppContext.js";
import Pusher from 'pusher-js';
import axios from "axios"; 

// Non-lazy imports
import OrganizationShow from "./Organizations/Show.js";
import Partnerships from "./Shared/Partnerships.js";
import OrganizationUserInvitePage from "./OrganizationUsers/OrganizationUserInvitePage.js";
import ImportAuth from "./Daas/TaskBoards/ImportAuth.js";

import i18n from './i18n/locales/i18n.js'; // Import your i18n instance
const language = localStorage.getItem('language');
if (language) {
  i18n.changeLanguage(language);
}

// Lazily import components
const LazyReplayShow = lazy(() => import('./Replays/Show'));
const LazyWebsite = lazy(() => import('./Themes/Website'));
const LazyPortal = lazy(() => import("./Daas/Portal/Portal.js"));
const LazyPay = lazy(() => import("./Milestones/Pay.js"));
const LazyPartnershipSignUp = lazy(() => import("./Registrations/PartnershipSignUp.js"));
const LazyOnboardingSellerRouter = lazy(() => import("./Onboardings/OnboardingSellerRouter.js"));
const LazyCollaboratorInvitePage = lazy(() => import("./Projects/CollaboratorInvitePage.js"));
const LazyInvite = lazy(() => import("./OrganizationUsers/Invite.js"));
const LazyRequestFile = lazy(() => import("./Projects/RequestFiles/RequestFile.js"));
const LazyPublicFolder = lazy(() => import("./Folders/PublicFolder.js"));
const LazyFinalDelivery = lazy(() => import("./Projects/Deliverables/FinalDelivery.js"));
const LazyReactNativeFile = lazy(() => import("./Replays/Forms/ReactNativeFile.js"));
const LazyDemo = lazy(() => import("./Shared/Demo.js"));
const LazyCheckout = lazy(() => import("./Daas/Checkout/Checkout.js"));
const LazyCatalog = lazy(() => import("./Daas/Seller/Catalog.js"));
const LazyDomain = lazy(() => import("./Sessions/Domain.js"));
const LazyOrganizations = lazy(() => import("./Organizations/Organizations.js"));
const LazyCheckoutSuccessful = lazy(() => import("./Daas/Stripe/CheckoutSuccessful.js"));
const LazyPaypalCheckoutSuccessful = lazy(() => import("./Daas/PaypalAccounts/CheckoutSuccessful.js"));
const LazyClientInvitePage = lazy(() => import("./Projects/ClientInvitePage.js"));
const LazyChangePassword = lazy(() => import("./Users/ChangePassword.js"));
const LazyReferralSignup = lazy(() => import("./Referrals/ReferralSignup.js"));
const LazyWidgetButton = lazy(() => import("./Chatrooms/Widget/WidgetButton.js"));
const LazyWidget = lazy(() => import("./Chatrooms/Widget/Widget.js"));
const LazyWidgetWelcomeMessage = lazy(() => import("./Chatrooms/Widget/WidgetWelcomeMessage.js"));
const LazySignIn = lazy(() => import("./CodeAuth/Signin.js"));
const LazyInvoiceCheckout = lazy(() => import("./Invoices/InvoiceCheckout.js"));
const LazyInvoiceCheckoutSuccess = lazy(() => import("./Invoices/InvoiceCheckoutSuccess.js"));
const LazyNewOauth = lazy(() => import("./Oauth/New.js"));
const LazyEditTheme = lazy(() => import("./Themes/EditTheme.js"));
const LazyAdminDashboard = lazy(() => import("./Admin/AdminDashboard.js"));

const App = () => {
  const history = useHistory();
  const routerLocation = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [counter, setCounter] = useState(0)
  // const [updatePassword, setUpdatePassword] = useState(false);
  const pusher = new Pusher('a5c19937d10212cddb4e', {cluster: 'us2'});
  const [statuses, setStatuses] = useState([]);
  const [notifications, setNotifications] = useState([]); 
  const [subdomainOrganization, setSubdomainOrganization] = useState(null);

  window.pusher = pusher; 
  // code to update the online status context 
  const increment = () => {
    setCounter(Math.floor(Math.random() * 100))
  }
  window.increment = increment;

  useEffect(() => {
    checkLogin();
    // fetchStatuses();
    // if(currentUser != null){
    //   fetchNotifications();
    // }
  }, []); // ! removed routerLocation. Don't know why we need this. 

  // Fetch statuses from the API endpoint
  const fetchStatuses = () => {
    axios.get(`/api/statuses.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setStatuses(response.data.statuses)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      // notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  useEffect(() => {
    if(currentUser != null){
      const channel = pusher.subscribe(`user-${currentUser.id}`);
      channel.bind(`user`, function(data) {
        // console.log("puserh channel user", data);
    
        // Check if the function exists, if it does then we execute it. 
        if(typeof window[data.function] === "function") {
          eval(`${data.function}()`)
        }
      })
      
      return () => {
        pusher.unsubscribe(`user-${currentUser.id}`);
      }
    }    
  },[currentUser]);

  function checkLogin() {
    // console.log("*", routerLocation);
    if (routerLocation.pathname != "/o" && window.location.pathname.includes("/o/")) {
      localStorage.setItem("location", routerLocation.pathname);
    }

    if (localStorage.getItem("location") != null) {
      if (localStorage.getItem("location") != location.pathname) {
        // window.location.href = localStorage.getItem("location");
      }
    }

    // check login
    $.ajax({
      url: `/api/check_login.json`,
      type: "GET",
      dataType: "json", // added data type
      success: (response) => {
        // console.log(response);
        if (response.success == true) {
          setLoaded(true);
          setLoggedIn(true);
          setCurrentUser(response.current_user);
        
          if (response.verified == false && !location.href.includes("replays") && !location.pathname.includes("services")) {
            // document.getElementById("updatePassword").click();
            // setUpdatePassword(true);
          }
          if(navigator.userAgent.toLowerCase().indexOf(' electron/') > -1){
            sendCurrentUserToElectron(response.current_user);
          }
        } else {
          if (window.location.pathname.includes("/o/") && !window.location.search.includes("redirect_url=")) {
            const encodedRedirectUrl = window.location.pathname
            window.location.href = `/continue?redirect_url=${encodedRedirectUrl}`;
          } else {
            setLoaded(true);
          }
        }
        setSubdomainOrganization(response.subdomain_organization);
      },
      error: (response) => {
        // window.location.href = `/not-found`;
      },
    });
  }
  window.checkLogin = checkLogin;

  const fetchNotifications = () => {
    // axios.get(`/api/notifications.json`)
    // .then(function(response){
    //   // console.log(response);
    //   if(response.data.success){
    //     setNotifications(response.data.notifications);
    //   } else {

    //   } 
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const markNotificationRead = (notifiable_type, notifiable_id) => {
    axios.post(`/api/notifications/read`, {
      notifiable_type: notifiable_type,
      notifiable_id: notifiable_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchNotifications();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  // universal way of redirecting user using example.js.erb files
  function redirect(url) {
    history.push(url);
  }
  window.redirect = redirect;  

  const status_list = statuses.map(status => {
    // Check if the status token is present in the local storage
    const isStatusClosed = localStorage.getItem(status.token);
    if (!isStatusClosed) {
      return(
        <div class="statuses-wrapper box-shadow animated fadeInDown border-all" key={status.token}>
          <div class="statuses-message">
            <i class="fas fa-wrench"></i>
            <div className="mr-10 ml-10">
              <div className="font-weight-600 font-size-16">
                {status.message}
              </div>

              <div className="mt-5">
                {status.description}
              </div>
            </div>
          </div>

          <i class="fas fa-times statuses-close-icon" onClick={() => {localStorage.setItem(status.token, true); setStatuses((prevStatuses) => prevStatuses.filter((item) => item.token !== status.token))}}></i>
        </div>
      )
    }
  });

  const reportError = (error) => {
    console.log(error);

    axios
    .post('/record_react_error', {
      errorInfo: error,
      // current_user: this.props.current_user
    })
    .then((response) => {
      console.log('Error data sent successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error sending error data:', error);
    });
  }
  window.reportError = reportError;

  return (
    <React.Fragment>
      {loaded && (
        <React.Fragment>
          <ApolloProvider client={client}>
            <DndProvider backend={HTML5Backend}>
              <ThemeProvider>
                <Switch>
                  <UserContext.Provider value={currentUser} checkLogin={checkLogin}>
                      <CounterContext.Provider value={{ counter, increment, subdomainOrganization }}>
                        <AppContext.Provider value={{checkLogin, fetchNotifications, notifications, markNotificationRead}}>
                          <Error>
                            <Route exact path="/new-workspace" component={NewWorkspace} />
                            <Route path="/o/:organization_id" component={OrganizationShow} />
                            
                            <Route exact path="/not-found" component={NotFound} />
                            <Route exact path="/partnerships/:partnership_id" component={Partnerships} />
                            <Route exact path="/organizations/:organization_id/workspace_invite" component={OrganizationUserInvitePage} />
                            <Route exact path="/auth/:auth_kind/projects/:project_id" component={ImportAuth} />
                            <Suspense fallback={<div></div>}>
                              <Route exact path="/oauth/:provider" component={LazyNewOauth} />
                              <Route path="/portal/o/:organization_id" component={LazyPortal} />
                              <Route exact path="/pay/:milestone_id" component={LazyPay} />
                              <Route path="/partnership_signup" component={LazyPartnershipSignUp} />
                              <Route path="/onboardings/:organization_id" component={LazyOnboardingSellerRouter} />
                              <Route exact path="/collaborator_invite/:project_id/:organization_id" component={LazyCollaboratorInvitePage} />
                              <Route exact path="/folders/:folder_id" component={LazyPublicFolder} />
                              <Route exact path="/organizations/:organization_id/invite" component={LazyInvite} />
                              <Route exact path="/request_files/:request_file_id" component={LazyRequestFile}/>
                              <Route exact path="/deliverables/:organization_id/:project_id" component={LazyFinalDelivery}/>
                              <Route exact path="/react_native/o/:organization_id/projects/:project_id/file_upload" component={LazyReactNativeFile}/>
                              <Route exact path="/services/:service_id/checkouts" component={LazyCheckout}/>
                              <Route exact path="/services" component={LazyCatalog}/>
                              <Route exact path="/demo" component={LazyDemo} />
                              <Route exact path="/register" component={LazySignIn} />
                              <Route exact path="/login" component={LazySignIn} />
                              <Route exact path="/continue" component={LazySignIn} />
                              <Route exact path="/signin" component={LazySignIn} />
                              <Route exact path="/domain" component={LazyDomain} />
                              <Route exact path="/organizations" component={LazyOrganizations} />
                              <Route exact path="/stripe/checkout_successful" component={LazyCheckoutSuccessful} />
                              <Route exact path="/paypal/:organization_id/:service_id/:paypal_price_id/checkout_successful" component={LazyPaypalCheckoutSuccessful} />
                              <Route exact path="/client_invite/:project_id/:organization_id" component={LazyClientInvitePage} />
                              <Route exact path="/change_password" component={LazyChangePassword} />
                              <Route exact path="/affiliates" component={LazyReferralSignup} />
                              <Route exact path="/:organization_id/chat-widget-button" component={LazyWidgetButton} />
                              <Route exact path="/:organization_id/chat-widget" component={LazyWidget} />
                              <Route exact path="/:organization_id/chat-widget-welcome" component={LazyWidgetWelcomeMessage} />
                              <Route exact path="/invoice/:invoice_id" component={LazyInvoiceCheckout} />
                              <Route exact path="/invoice/:invoice_id/:organization_id/:project_id/invoice_checkout_success" component={LazyInvoiceCheckoutSuccess} />
                              <Route exact path="/organization_themes/:organization_id/themes/:organization_theme_id" component={LazyEditTheme}/>
                              <Route exact path="/" component={LazyWebsite} />
                              <Route exact path="/replays/:replay_id" component={LazyReplayShow} />
                              <Route exact path="/admin_dashboard" component={LazyAdminDashboard} />
                            </Suspense>
                          </Error>
                        </AppContext.Provider>
                      </CounterContext.Provider>
                  </UserContext.Provider>
                </Switch>

                {status_list}
              </ThemeProvider>
            </DndProvider>
          </ApolloProvider>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default App;